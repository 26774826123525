body.kbd.subpage {
  main.container {
    overflow: hidden;
  }

  .content-wrapper.three-col section.main {
    padding: 0 10px;

    @media screen and (min-width: 768px) {
      padding: 0 30px;
    }
  }

  ul {
    li {
      &:before {
        content: none;
      }
    }
  }

  #KnowledgeBaseSearch_upSearchResults {
    position: relative;

    &:after {
      width: 47px;
      position: absolute;
      height: 76px;
      background: linear-gradient(to right, rgba(255, 255, 255, 0.75) 0%, white 80%);
      top: 1px;
      left: calc(100% - 10px);
      content: '';

      @media screen and (min-width: 768px) {
        content: none;
      }
    }
  }

  ul.drivve-filter {
    list-style: none;
    margin: 25px auto;
    padding: 20px 0;
    background: transparent;
    border-top: 1px solid #dcdcdc;
    border-bottom: 1px solid #dcdcdc;
    white-space: nowrap;
    overflow-x: auto;

    li {
      display: inline-block;
      margin-right: 10px;

      a {
        font-family: inherit;
        font-weight: 400;
        letter-spacing: .03em;
        font-size: 14px;
        line-height: 24px;
        border: 1px solid #d9d9d9;
        padding: 5px 10px;
        cursor: pointer;
        display: inline-block;
        background-color: #ffffff;
        color: #333333;
        border-radius: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f4f4f4;
          text-decoration: none;
        }
      }
    }
  }

  table {
    border: none;

    tbody {
      tr {
        border: 1px solid #dcdcdc;
        border-bottom-width: 0;
        background: #f9f9f9;

        &:nth-child(even) {
          background: #f0f0f2;
        }

        &:last-child {
          border-bottom-width: 1px;
        }

        th {
          display: none;
        }

        td {
          padding: 30px 20px;
          border: none;

          a {
            display: block;
            font-weight: 700;
            margin-bottom: 10px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .drivve-search-options {
    table.drivve-product-filter {
      margin-bottom: 30px;

      tbody {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media screen and (min-width: 786px) {
          flex-wrap: nowrap;
        }

        tr {
          width: 100%;
          border: 1px solid #dcdcdc;
          border-top: 0;
          background: #ededed;
          padding: 15px;

          @media screen and (min-width: 768px) {
            border-right-width: 0;
          }

          &:last-child {
            border-right-width: 1px;
          }

          td {
            display: block;
            padding: 0;

            span {
              width: 100%;
              display: inline-flex;
              align-items: center;

              input[type="checkbox"] {
                min-width: 25px;
                max-width: none;
                height: 25px;
                padding: 0;
                background: #fff;
                border: 1px solid #dcdcdc;
                margin-right: 10px;
              }

              input[checked="checked"]:after {
                content: '\f00c';
                font-family: FontAwesome;
                line-height: 23px;
                text-align: center;
                width: 24px;
                display: inline-block;
              }

              label {
                width: 100%;
              }
            }
          }
        }
      }

      input[type=radio] {
        appearance: checkbox;
        margin-right: 10px;
      }

      input[type=radio] {
        appearance: radio;
        margin-right: 10px;
      }

      .logo {
        height: 15px;
        max-width: 104px;
        width: auto;

        &.drivve-image {
          background: url(/themes/EOM/assets/dist/images/logo-drivve-image.svg) no-repeat;
          background-size: contain;
        }

        &.drivve-print {
          background: url(/themes/EOM/assets/dist/images/logo-drivve-print.svg) no-repeat;
          background-size: contain;
        }

        &.drivve-dm {
          background: url(/themes/EOM/assets/dist/images/logo-drivve-dm.svg) no-repeat;
          background-size: contain;
        }
      }
    }

    .search-drivve {
      display: flex;
      margin-bottom: 1rem;
      padding-right: 0.625rem;
      width: 100%;
      align-items: stretch;
      background: #f5f5f5;
      padding: 20px;
      border: 1px solid #e2e2e2;
      margin-bottom: 0;

      > div{
        display: flex;
        width: 100%;
      }

      input[type=text] {
        padding: 10px 25px;
        width: calc(100% - 55px);
        border-radius: 5px 0 0 5px;
        box-shadow: none;
        color: #777;
        font-size: .75rem;
        display: inline-block;
        border: 1px solid #d9d9d9;
      }

      a.drivve-search {
        display: inline-block;
        padding: 17px;
        border: 1px solid #d9d9d9;
        border-left: 0;
        border-radius: 0 5px 5px 0;
        background-color: #f6f6f6;
        color: #777;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: .0625rem;
        font-weight: 700;
        font-size: .75rem;
        font-family: Roboto, sans-serif;
        cursor: pointer;

        > span {
          font-size: 20px;
          color: #01349f;
        }
      }
    }
  }

  .empressionCollapsible {
    + p {
      margin: 0;
    }

    .empressionCollapsible-title {
      border: 0;
      padding: 0;
      margin: 0;
      font-size: 16px;
      color: #0032a1;
      display: block;
      padding: 5px 10px;
      background: #f2f2f2;
      position: relative;
      cursor: pointer;

      &:after {
        content: '\f0d7';
        font-family: "FontAwesome";
        font-size: 18px;
        line-height: 41px;
        position: absolute;
        right: 20px;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    > *:not(:first-child) {
      padding: 0 25px;
    }

    &.collapsed {
      .empressionCollapsible-title {
        + * {
          display: none;
        }
      }
    }
  }

  div.drivveBoxesAlert,
  div.drivveBoxesInfo {
    position: relative;
    min-height: 30px;
    padding: 20px 20px 20px 60px;
    margin-left: 0px;
    margin-bottom: 20px;
    border-radius: 11px;
    background-color: #fffae6;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, .15);

    &:before {
      background: url(https://drivve.de/v3/img/main/alert.svg) no-repeat;
      background-size: 32px;
      background-position: 2px center;
    }
  }

  title {
    display: block;
    font-size: 2rem;
    margin-bottom: 40px;
    margin-top: 40px;
  }

  .drivveBoxesInfo > a {
    display: block;
    margin-top: 10px;
    font-weight: bold;

    &:before {
      background-image: url(https://drivve.de/v3/img/main/info.svg);
    }
  }

  a {
    img {
      display: none;
    }
  }
}

.article-actions {
  padding: 15px;
  background: #f5f5f5;
  border: 1px solid #e2e2e2;
  border-radius: 0;
  display: flex;
  /* flex-grow: 2; */
  width: 100%;

  a {
    display: block;
    width: 100%;
    width: 100%;
    text-align: center;
    background: #fff;
    margin-right: 15px;
    padding: 5px 0;
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    font-size: 14px;

    &:last-child {
      margin-right: 0;
    }
  }
}
