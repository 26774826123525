.search-results {
  .search-results-search {
    display: flex;
    margin: 30px 0;

    input[type='search'] {
      padding: 0.75em 2em;
      min-width: 9.375rem;
      width: 100%;
      border: solid 2px #e6e6e6;
      border-radius: 1.25rem 0 0 1.25rem;
      box-shadow: none;
      color: #777777;
      font-size: 0.75rem;
    }

    input[type='submit'] {
      display: inline-block;
      padding: 0.75em 2em;
      border: solid 2px #e6e6e6;
      border-left: none;
      border-radius: 0 50px 50px 0;
      background-color: #f6f6f6;
      color: #777777;
      vertical-align: middle;
      text-align: center;
      text-transform: uppercase;
      letter-spacing: 0.0625rem;
      font-weight: 700;
      font-size: 0.75rem;
      font-family: Roboto,sans-serif;
      cursor: pointer;
    }
  }

  .stats {
    display: block;
    float: right;
    margin: 6px 3px 0 0;
    color: #666666;
    vertical-align: top;
  }

  table.results {
    tr {
      background: white;

      th {
        &:first-child {
          display: none;
        }
      }

      td {
        padding: 12px 0 30px;

        span.excerpt {
          display: block;
          margin-top: 10px;
        }
      }
    }

    .pagination {
      text-align: center;
    }
  }
}
