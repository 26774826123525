body.print {
  h1 {
    color: #333333;
    font: 300 30px/36px 'Roboto', sans-serif;
  }

  h1.white {
    color: white;
  }

  h1.large {
    font: 300 40px/48px 'Roboto', sans-serif;
  }

  h2 {
    color: #333333;
    font: 700 25px/30px 'Roboto', sans-serif;
  }

  h3 {
    color: #333333;
    font: 700 21px/30px 'Roboto', sans-serif;
  }

  h4 {
    color: #333333;
    font: 700 18px/30px 'Roboto', sans-serif;
  }

  h5 {
    color: #333333;
    text-transform: uppercase;
    letter-spacing: 2px;
    font: 500 16px/18px 'Roboto', sans-serif;
  }

  h6 {
    color: #333333;
    font: 400 18px/20px 'Roboto', sans-serif;
    font-style: italic;
  }

  p {
    margin: 0 0 20px 0;
    padding: 0;
    color: #333333;
    font: 400 18px/30px 'Roboto', sans-serif;
  }

  p.large {
    font: 300 30px/42px 'Roboto', sans-serif;
  }

  .unauthorized-access-cta p.large,
  .release-cta p.large,
  .watermarks-cta p.large,
  .audit-cta p.large,
  .protect-print-data-cta p.large,
  .protect-printers-cta p.large {
    margin-bottom: 10px;
    font: 400 30px/42px 'Roboto', sans-serif;
  }

  a {
    font-weight: 700;
    margin: 0;
    padding: 0;
    text-decoration: none;
    cursor: pointer;
  }

  // a:hover {
  //   text-decoration: underline;
  // }

  // Remove margin and padding from heading container, change h1 color to white
  .header-right h1 {
    margin: 0;
    padding: 0;
  }

  // Center subpage title h1 and adjust size and color
  .page-title-text h1 {
    position: relative;
    top: 50%;
    left: auto;
    margin: 0;
    padding: 0;
    height: auto;
    text-transform: none;
    text-shadow: none;
    transform: translateY(-50%);
  }

  // Paragraph style for homepage buttons
  .hp-btn-text {
    // position: relative;
    top: 50%;
    color: white;
    text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.45);
    font: 700 18px/20px 'Roboto Condensed', sans-serif;
    transform: translateY(-50%);
  }

  // Align menu text with icon
  .menu-btn span {
    margin-top: 5px;
    color: white;
    font: 300 30px/36px 'Roboto', sans-serif;
  }

  // General class to vertically align text
  .vertical-align {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  // Styles for resolutions 1024px and below
  @media only screen and (max-width: 1024px) {
    .hp-btn-text {
      position: relative;
      font: 700 24px/30px 'Roboto Condensed', sans-serif;
      // top: 0;
      // transform: none;
    }

    .header-right h1 {
      font-size: 24px;
    }

    h1.large {
      font: 300 36px/42px 'Roboto', sans-serif;
    }
  }

  // Styles for resolutions 500px and below
  @media only screen and (max-width: 500px) {
    .hp-btn-text {
      font: 700 20px/28px 'Roboto Condensed', sans-serif;
      // transform: none;
    }

    .header-right h1,
    .page-title-text h1 {
      font-size: 18px;
    }

    h1.large {
      font: 300 24px/36px 'Roboto', sans-serif;
    }
  }
}
