.subpage {
  .container {
    margin-right: auto;
    margin-left: auto;
    padding-right: 0.9375rem;
    padding-left: 0.9375rem;

    .content-wrapper {
      display: flex;
      margin-right: auto;
      margin-left: auto;

      // padding: 0 1.25rem;
      max-width: 75rem;
      flex-flow: row wrap;

      @media only screen and (max-width: 1023px) {
        display: block;
        margin-top: 3.125rem;
        // padding: 0 1.25rem;
      }
    }
  }

  .content-wrapper.two-column {
    display: flex;

    section.main {
      flex: 0 0 75%;
      margin: 0;
      padding-right: 0;
      padding-left: 35px;
      max-width: 75rem;
      max-width: 75%;
      order: 2;

      @media only screen and (max-width: 1023px) {
        flex: 0 0 100%;
        // padding: 0 1.25rem;
        padding: 0;
        max-width: 100%;
        order: 1;
      }
    }
  }

  .content-wrapper.three-col {
    display: flex;

    section.main {
      flex: 0 0 54%;
      margin: 0 0 100px;
      padding-right: 30px;
      padding-left: 30px;
      max-width: 75rem;
      max-width: 54%;
      order: 2;

      @media only screen and (max-width: 1023px) {
        flex: 0 0 100%;
        margin: 0;
        max-width: 100%;
        order: 1;
      }

      &.middle {
        margin: 0 auto;
      }
    }
  }

  .sidebar {
    position: relative;
    flex: 0 0 23%;
    max-width: 23%;
    color: #333333;
    font-style: normal;
    font-size: 14px;
    font-family: Roboto, sans-serif;
    order: 1;

    @media only screen and (max-width: 1023px) {
      flex: 0 0 100%;
      max-width: 100%;
      order: 2;
    }

    nav.sub-navigation {
      position: relative;
      margin: 0 0 2.1875rem 0;
      border: 1px solid #e2e2e2;
      line-height: 1.6rem;

      &.default {
        .view-more {
          position: absolute;
          top: 0;
          right: 0;
          width: 50px;
          height: 52px;
          color: #9c9c9c;
          text-align: center;
          cursor: pointer;

          span {
            position: relative;
            top: 13px;
          }

          &:hover {
            background: #ebebeb;
          }
        }

        .ul2 {
          padding: 0;

          li {
            position: relative;
            border: none;
            list-style: none;

            &:after {
              display: none;
            }

            a {
              position: relative;
              display: block;
              padding: 0.5rem 0.9375rem 0.5rem 2.625rem;
              border: none;
              color: #595959;
              text-decoration: none;
              font-size: 14px;

              &:before {
                position: absolute;
                left: 25px;
                content: '>';
              }
            }
          }
        }
      }

      .title {
        display: none;
      }

      .ul1 {
        position: relative;
        margin: 0;
        padding: 0;
        background: #f5f5f5;
        list-style: none;

        li {
          position: relative;
          border-bottom: 1px solid #e2e2e2;
          font-size: 0.875rem;

          a {
            position: relative;
            display: block;
            padding: 1.125rem 2.675rem 1rem 1.25rem;
            border-left: 5px solid rgba(146, 146, 146, 0.1);
            background-size: 3.1875rem;
            color: #333333;
            text-decoration: none;
            line-height: 1.125rem;

            &:hover {
              background-color: #ebebeb;
            }
          }

          .ul2 {
            display: none;
          }

          &.active {
            .ul2 {
              display: block;
            }
          }
        }
      }
    }

    nav.sub-navigation.other {
      margin-top: -2.3125rem;
      border: none;

      .other-solutions {
        position: relative;
        margin: 0 0 2.1875rem 0;
        border: 1px solid #e2e2e2;

        .view-more {
          position: relative;
          display: block;
          padding: 0.9375rem;
          width: 100%;
          border: none;
          border-radius: 0;
          background-color: #757575;
          color: #ffffff;
          text-transform: uppercase;
          font-size: 12px;
          cursor: pointer;

          &:after {
            position: absolute;
            top: 12px;
            right: 20px;
            content: '\f0d7';
            font-size: 15px;
            font-family: FontAwesome;
          }
        }

        .view-more.open {
          &:after {
            content: '\f0d8';
            font-family: FontAwesome;
          }
        }

        .nav-items {
          display: none;

          .ul1 {
            position: relative;
            margin: 0;
            padding: 0;
            background: #f5f5f5;
            list-style: none;

            li {
              position: relative;
              border-bottom: 1px solid #e2e2e2;
              font-size: 0.875rem;
              line-height: 1.125rem;

              &:before {
                position: absolute;
                top: 0;
                left: 0;
                z-index: 1;
                overflow: hidden;
                width: 50px;
                height: 52px;
                border-right: 1px solid #e2e2e2;
                background-color: #ebebeb;
                content: '';
              }

              a {
                position: relative;
                display: block;
                padding: 1.125rem 2.5rem 1rem 4.1875rem;
                border-left: none;
                background-size: 3.1875rem;
                color: #333333;
                text-decoration: none;

                &:after {
                  display: none;
                }
              }
            }

            li.healthcare {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-healthcare.svg') no-repeat center center;
              }
            }

            li.manufacturing {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-manufacturing-logistics.svg') no-repeat center center;
              }
            }

            li.financial {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-financial-services.svg') no-repeat center center;
              }
            }

            li.workplace {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-workplace-printing.svg') no-repeat center center;
              }
            }

            li.modernization {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-modernization.svg') no-repeat center center;
              }
            }

            li.output-management {
              &:before {
                background: url('/Themes/EOM/assets/dist/images/icon-output-management.svg') no-repeat center center;
              }
            }
          }
        }

        .nav-items.open {
          display: block !important;
        }
      }
    }
  }

  .subpage-banner {
    position: relative;
    margin: 18px 0 0;

    // max-height: 6.25rem;
    // height: 6.25rem;
    background: #062d89 url('/Themes/EOM/assets/dist/images/bnr-subpage.jpg') no-repeat center center;
    background-size: cover;

    .page-title {
      position: relative;
      display: inline-block;
      padding-bottom: 35px;
      width: 100%;

      h1 {
        position: relative;
        top: 30px;
        padding-bottom: 20px;
        height: auto;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        text-shadow: 0 1px 5px rgba(0, 0, 0, 0.6);
        font-weight: 400;
        font-size: 22px;
        font-family: Roboto, sans-serif;
      }
    }
  }

  section.main {
    margin-right: auto;
    margin-left: auto;
    max-width: 75rem;

    @media only screen and (max-width: 1023px) {
      margin-top: 115px;
      padding: 0;
    }
  }
}
