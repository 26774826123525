
#map_bottom {
    margin-top: 1rem;

    .row {
      display: flex;
      align-content: flex-start;

      @media only screen and (max-width: 768px) {
        display: block;
      }

      .col:first-of-type {
        width: 350px;
      }
    }
    
    #legend {
        h1.legend-title {
            margin-top: 0px;
        }
        .map-legend {
            list-style: none;
            li {
                font-size: .75rem;
                line-height: 16px;
                vertical-align: middle;
                &::before {
                    content: '';
                    display: inline-block;
                    width: 22px;
                    height: 16px;
                    background-position: center center;
                    margin-right: .25rem;
                }
            }
            .legend-corp {
                &::before {
                    background: url(/themes/EOM/assets/dist/images/icn-eom-map-corp.png) no-repeat center center;
                }
            }
            .legend-onDuty {
                &::before {
                    background: url(/themes/EOM/assets/dist/images/icn-eom-map-office-on.png) no-repeat center center;
                }
            }
            .legend-offDuty {
                &::before {
                    background: url(/themes/EOM/assets/dist/images/icn-eom-map-office-off.png) no-repeat center center;
                }
            }
            .legend-dist {
                &::before {
                    background: url(/themes/EOM/assets/dist/images/icn-eom-map-dist.png) no-repeat center center;
                }
            }
        } // .map-legend
        .map-instructions {
            font-size: .75rem;
            font-weight: bold;
        }
    } // #legend
    .map-actions {
        
        margin: 2.5rem 0 0 0;
        padding: 0;
        position: relative;

        li {
            
            display: inline-block;
            width: 40%;         
            vertical-align: top;      
            text-align: center;

            background: #ebebeb;
            border-radius: 10px;
            padding: 10px;
            border: solid 2px #e6e6e6;                

            a {
                text-decoration: none;
                .heading {
                    font-weight: bold;
                    font-size: 1.25rem;
                }
                small {
                    font-size: 14px;
                    display: block;
                    line-height: 1rem;
                }
                &:hover {
                    text-decoration: none;
                }
            }

            &.btn-login {
                background-color: #0032a0;
                border-color: #0032a0;
                a {
                    color: #fefefe;
                }
                &:hover {
                    background-color: #002880;
                }
            }
            &.btn-basics {
                background-color: #757575;
                border-color: #757575;
                a {
                    color: #fefefe;
                }
                &:hover {
                    background-color: #5f5f5f;
                }                    
            }
        }


    }
}