.tooltip {
  // pointer-events: none;
  position: absolute;
  visibility: hidden;
  padding: 30px;
  // max-width: 260px;
  @include roboto;
  @include fontsize(14 16);
  text-align: center;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.45);
  color: white;
  // font-style: italic;
  border-radius: 5px;
  background: $ceruleanblue;
  // background: linear-gradient(to bottom, rgba($scienceblue, 1) 0%, rgba($cerulean, 1) 100%);
  // box-shadow: 0px 0px 5px 0px rgba(black, 0.25);

  &[data-tooltip-position='top'] {
    // position: absolute;
    bottom: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    &:after {
      position: absolute;
      bottom: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 0 10px;
      border-color: $ceruleanblue transparent transparent transparent;
      content: '';
    }
  }

  &[data-tooltip-position='bottom'] {
    // position: absolute;
    top: calc(100% + 10px);
    left: 50%;
    transform: translateX(-50%);

    &:after {
      position: absolute;
      top: -10px;
      left: 0;
      right: 0;
      margin: 0 auto;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $ceruleanblue transparent;
      content: '';
    }
  }

  &[data-tooltip-position='left'] {
    right: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 0 10px 10px;
      border-color: transparent transparent transparent $ceruleanblue;
      content: '';
    }
  }

  &[data-tooltip-position='right'] {
    position: absolute;
    left: calc(100% + 10px);
    top: 50%;
    transform: translateY(-50%);

    &:after {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -10px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 10px 10px 0;
      border-color: transparent $ceruleanblue transparent transparent;
      content: '';
    }
  }

  &[aria-hidden='false'] {
    visibility: visible;
    z-index: 3000;
  }

  .arrow {
    position: absolute;
  }

  .tooltip-close {
    cursor: pointer;
    position: absolute;
    top: 6px;
    // left: 6px;
    right: 6px;
    padding-top: 3px;
    width: 24px;
    height: 24px;
    border: 2px solid white;
    border-radius: 50%;
    text-shadow: none;
    text-align: center;

    &:before {
      font-size: 12px;
      color: white;
    }
  }
}

// tooltip indicator/link
[role='tooltip'] {
  color: $resolutionblue;

  &:hover,
  &:focus {
    color: $pacificblue;
  }
}