.subpage-banner.blog {
  margin-bottom: 50px;
}

main {
  section.main {
    &.blog {
      margin-top: 50px;
    }
  }
}

.blog-search {
  position: relative;
  overflow: hidden;
  margin: 1rem 0;
  border: 1px solid #d5d5d5;

  .blog-search-input {
    float: left;
    margin-bottom: 0;
    padding: 0 0.875rem;
    width: calc(100% - 73px);
    height: 2.0625rem;
    border: 0;
    background: 0 0;
    font-size: 0.8125rem;
    line-height: 0.8125rem;
  }

  .blog-search-submit {
    display: block;
    float: right;
    margin: 0;
    padding: 0;
    width: 2.625rem;
    height: 2.0625rem;
    border: 0;
    background: transparent url(../images/btn-blog-search.jpg) top center;
    text-indent: -9999rem;
    font-size: 1px;
    line-height: 1px;
    cursor: pointer;
  }
}

.blog {
  font-family: Roboto,sans-serif;

  a {
    color: #0032a0;
    font-weight: 700;
  }

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: 20px;
    color: #0032a0;
    font-weight: 500;
    font-size: 25px;
  }

  > a {
    display: inline-block;
    margin: 20px 10px 10px 0;
    padding: 15px 30px;
    border-radius: 4px;
    background: #0032a0;
    color: #ffffff;
    text-decoration: none;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 400;
    font-size: 14px;
    line-height: 13px;

    &:hover {
      background: #00226d;
    }

    &.back-to {
      color: white;
    }
  }

  .blog-author {
    margin: 50px 0;
  }

  .post-date {
    padding: 15px 0;
    border-top: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    color: #757575;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-size: 14px;
  }

  .post-detail {
    p {
      margin-bottom: 1rem;
      font-size: inherit;
      line-height: 2rem;
      text-rendering: optimizeLegibility;

      &:first-of-type {
        // padding-top: 45px;
      }
    }

    a.back-to {
      position: relative;
      bottom: -2px;
      font-size: 14px;

      &:hover {
        color: #0032a0;
        text-decoration: none;
      }

      &:before {
        padding-right: 10px;
        content: '\f060';
        font-family: FontAwesome;
      }

      @media (max-width: 640px) {
        display: block;
        padding: 20px 0;
        text-align: left;
      }
    }
  }

  .post-translations {
    display: flex;
    overflow: hidden;
    padding: 0.75rem 0;
    border-bottom: 1px dotted #d5d5d5;
    align-items: center;

    span {
      display: inline-block;
      float: left;
      margin-right: 0.625rem;
      color: #404040;
      font-weight: 700;
      font-size: 0.9375rem;
      line-height: 1.125rem;
    }

    ul {
      display: inline-block;
      float: left;
      margin: 0 !important;
      padding: 0;
      list-style-type: none;

      li {
        display: inline-block;
        margin-right: 5px;
        margin-bottom: 0;

        a {
          display: block;
          padding: 0.25rem 0.625rem;
          border: 1px solid #d5d5d5;
          border-radius: 4px;
          color: #404040;
          text-decoration: none;
          font-size: 0.875rem;
          line-height: 0.875rem;

          &:visited {
            border: 1px solid #d5d5d5;
            color: #404040;
          }

          &:hover {
            border: 1px solid #0032a0;
            background-color: #0032a0;
            color: #ffffff;
            text-decoration: none;
          }
        }
      }
    }
  }
}

.blog-header {
  margin-bottom: 0;
  padding-bottom: 0.75rem;
  border-bottom: 1px solid #d5d5d5;
  color: #0032a0;
  font-size: 24px;
  font-family: Roboto,sans-serif;
}

.languages.listing {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 2.5rem;
  padding: 0.75rem 0;
  border-bottom: 1px solid #d5d5d5;
  font-family: Roboto,sans-serif;

  > span {
    display: inline-block;
    padding-bottom: 5px;
    color: #404040;
    font-weight: 700;
    line-height: 1.125em;
  }

  .fa-globe {
    position: relative;
    bottom: -2px;
    margin-right: 4px;
    color: #9c9c9c;
    font-size: 21px;
  }

  ul {
    display: inline-block;
    margin: 0;
    list-style-type: none;

    li {
      display: inline-block;
      border: 1px solid #d5d5d5;
      border-radius: 4px;
      font-size: 14px;

      &:hover {
        background: #0032a0;

        a {
          color: #ffffff;
        }
      }

      a {
        display: block;
        padding: 0 10px;
        color: #000000;
        text-decoration: none;
      }
    }
  }

  .subscribe-link {
    position: relative;
    top: 10px;
    float: right;

    span.fa {
      margin-right: 5px;
    }
  }
}

.posts {
  display: flex;
  width: 100%;
  flex-wrap: wrap;

  .post-listing {
    position: relative;

    // display: inline-block;
    flex: 1 auto;
    overflow: hidden;
    margin: 0 5px 20px;
    padding: 16px 20px;
    width: 32.5%;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 10px;
    vertical-align: top;
    font-family: Roboto,sans-serif;

    &:nth-of-type(3n + 1) {
      margin-left: 0;
    }

    .title {
      h2 {
        margin-top: 0;
        color: #0032a0;
        font-weight: 700;
        font-size: 18px;
      }

      .post-meta {
        color: #757575;
        text-transform: uppercase;
        font-weight: 400;
        font-size: 12px;

        &:after {
          position: relative;
          display: block;
          margin: 25px 0 35px;
          width: 100%;
          border: 1px solid #e6e6e6;
          content: '';
        }
      }
    }

    .post-content {
      padding-bottom: 40px;

      .post-image {
        position: relative;
        top: -16px;
        margin-right: -20px;
        margin-left: -20px;
        height: 150px;
        background-image: url('/themes/EOM/assets/dist/images/blog-default.png');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
      }

      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      .post-link {
        display: inline-block;
        margin: 20px 10px 10px 0;
        padding: 5px 8px;
        border-radius: 4px;
        background: #0032a0;
        color: #ffffff;
        text-decoration: none;
        font-weight: 600;
        font-size: 13px;
        line-height: 13px;

        &:hover {
          background: #00226d;
        }
      }
    }

    .read-more {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 20px;
      width: 100%;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #f6f6f6;
    }
  }
}


