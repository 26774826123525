.mobile-header {
  width: 100%;

  @media only screen and (min-width: 1024px) {
    display: none;
  }

  .mobile-menu {
    position: absolute;
    z-index: 1;
    display: none;
    min-height: 100vh;
    width: 100%;
    height: 100%;
    background: #6f6f73;
    font-weight: 400;
    font-style: normal;

    &:after {
      position: fixed;
      top: 0;
      z-index: 0;
      width: 100%;
      height: 100vh;
      background: #6f6f73;
      content: '';
    }

    .mobile-wrapper {
      position: absolute;
      right: 0;
      z-index: 160;
      min-height: 100%;
      width: 100%;
    }

    .ul1 {
      display: block;
      margin-top: 40px;
      padding: 0 30px 5px;
      background: transparent;
      list-style: none;
      font-weight: 400;
      font-size: 18px;
      font-family: Roboto, sans-serif;

      .li1 {
        display: block;
        margin-bottom: 30px;

        .a1 {
          position: relative;
          display: inline-block;
          padding-bottom: 10px;
          width: calc(100% - 50px);
          border-bottom: solid 2px rgba(255,255,255,0.1);
          color: #ffffff;
          vertical-align: middle;
          text-decoration: none;
          font-size: 18px;

          &:before {
            position: absolute;
            margin-left: -30px;
            color: #ffffff;
            font-size: 15px;
            font-family: FontAwesome;
          }
        }

        .ul2 {
          padding: 0;
          list-style: none;

          .li2 {
            line-height: 1.0625rem;

            &:first-child {
              margin-top: 10px;
            }

            .a2 {
              display: block;
              padding: 15px 0;
              border-bottom: none;
              color: #ffffff;
              text-decoration: none;
              font-weight: 700;
              font-size: 14px;
            }
          }

          .ul3 {
            display: none;
          }
        }
      }
    }

    .ul2.is_child .li2 {
      display: none;
    }

    .ul2.is_child .li2.show {
      display: block;
    }

    .li1.has_children {
      cursor: pointer;

      span {
        position: relative;
        top: -10px;
        display: inline-block;
        float: right;
        width: 45px;
        height: 100%;
        border-bottom: 2px solid rgba(255,255,255,0.5);
        color: #ffffff;
        text-align: center;
        line-height: 40px;

        span.fa {
          border-bottom: none !important;
        }
      }
    }
  }
}

.btn-mobile-open-nav {
  position: relative;
  top: 85px;
  display: inline-block;
  overflow: hidden;
  margin-top: -15px;
  margin-right: 35px;
  padding: 15px 0 0 25px;
  height: 64px;
  border-left: solid 2px #e6e6e6;
  cursor: pointer;

  @media only screen and (max-width: 1024px) {
    position: absolute;
    right: 0;
    z-index: 2;
  }

  span {
    position: relative;
    top: 0;
    display: inline-block;
  }

  span.menu-open {
    width: 30px;
    height: 30px;
    background-image: url('/themes/EOM/assets/dist/images/hamburger-icon.png');
    background-repeat: no-repeat;
    content: '';
  }

  span.menu-close {
    width: 30px;
    height: 23px;
    background-image: url('/themes/EOM/assets/dist/images/hamburger-icon-open.png');
    background-repeat: no-repeat;
    content: '';
  }
}

.btn-mobile-close-nav {
  display: block;
  padding: 45px 35px 22px;
  background: #ffffff;
  color: #17468f;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
  font-style: normal;
  font-size: 26px;
  line-height: 26px;
  cursor: pointer;

  @media only screen and (max-width: 320px) {
    padding: 50px 20px;
  }

  span {
    position: relative;
    top: -7px;
    margin-left: 0.4rem;
    font-weight: 700;
    font-style: normal;
    font-size: 11px;
  }

  span.menu {
    position: absolute;
    top: 40px;
    left: 25px;
    text-transform: none;
    font-weight: 500;
    font-style: normal;
    font-size: 36px;
  }
}

.mobile-search-box-container {
  position: relative;
  display: block;
  padding: 1.875rem 1.25rem;
  width: 100%;
  background: #646467;

  .mobile-search-box {
    display: inline-flex;
    width: 100%;

    input {
      padding: 8px 12px;
      width: 100%;
      height: 35px;
      border: 1px solid #cacaca;
      border-radius: 1.75rem;
      background: #ffffff;
      color: #0a0a0a;
      font-style: italic;
      font-size: 12px;
    }

    button {
      position: absolute;
      top: 30px;
      right: 19px;
      padding: 6px 12px;
      height: 35px;
      border: none;
      border-radius: 0 1.5625rem 1.5625rem 0;
      background-color: #949499;
      cursor: pointer;

      &:before {
        position: relative;
        top: 0;
        right: 0;
        color: #ffffff;
        content: '\f002';
        font-size: 16px;
        font-family: FontAwesome;
      }

      span.search-btn {
        position: absolute;
        top: 32px;
        right: 3px;
        padding-right: 5px;
        color: #000000;
      }
    }
  }
}

.mobile-quick-links {
  ul.menu {
    display: flex;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
    background: transparent;
    list-style: none;
    font-family: Roboto, sans-serif;
    border-top: 3px solid #545458;
    border-bottom: 3px solid #545458;


    li {
      text-align: center;
      display: inline-block;
      flex: 1;
      border-right: 3px solid #545458;
      
      a {
        background: #646467;
        color: #ffffff;
        vertical-align: middle;
        text-decoration: none;
        font-size: 18px;
        display: inline-block;
        position: relative;
        display: inline-block;
        padding: 15px;
        /* width: calc(100% - 50px); */
        width: 100%;
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}

.show-for-sr,
.show-on-focus {
  position: absolute!important;
  overflow: hidden;
  clip: rect(0,0,0,0);
  width: 1px;
  height: 1px;
}

.fa-close:before {
  color: #12a8e0;
  content: '\f00d';
}

.fixed {
  @media only screen and (max-width: 1023px) {
    position: fixed !important;
  }
}
