$base-font-size: 100%;
// assets path
$assets: '/Portals/_default/Skins/SC/assets/dist';
// colors
$alabaster: #f7f7f7;
$mercury: #e6e6e6;
$dovegray: #737373;
$mineshaft: #333333;
// $toreabay: #0a3b9b;
$prussianblue: #001c59;
$pear: #cbdb30;
// $navyblue: #00087c;
// $resolutionblue: #002474;
$resolutionblue: #002880;
$kleinblue: #0032a0;
$ceruleanblue: #2754b0;
$pictonblue: #23b4e8;
$pacificblue: #0097c9;

// fonts
@mixin roboto {
  font-family: 'Roboto', Arial, Helvetica, sans-serif;
}

@mixin roboto-reg {
  @include roboto;
  font-weight: 400;
}

@mixin roboto-light {
  @include roboto;
  font-weight: 300;
}

@mixin roboto-med {
  @include roboto;
  font-weight: 500;
}

@mixin roboto-bold {
  @include roboto;
  font-weight: 700;
}

@mixin roboto-black {
  @include roboto;
  font-weight: 900;
}

@mixin robotoC {
  font-family: 'roboto-condensed', Impact, "Franklin Gothic Bold", sans-serif;
}

@mixin robotoC-reg {
  @include robotoC;
  font-weight: 400;
}

@mixin robotoC-reg-italic {
  @include robotoC;
  font-weight: 400;
  font-style: italic;
}

@mixin robotoC-light {
  @include robotoC;
  font-weight: 300;
}

@mixin robotoC-bold {
  @include robotoC;
  font-weight: 700;
}

@mixin robotoC-bold-italic {
  @include roboto-con;
  font-weight: 700;
  font-style: italic;
}
