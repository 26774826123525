.content {
  .content-form {
    fieldset {
      padding: 0;
      border: 0;

      > div {
        margin-bottom: 15px;
      }

      h2 {
        padding-bottom: 10px;
      }
    }

    p {
      margin-top: 0;
      line-height: 80%;
    }

    input[type='text'],
    input[type='password'],
    input[type='date'],
    input[type='datetime'],
    input[type='datetime-local'],
    input[type='month'],
    input[type='week'],
    input[type='email'],
    input[type='number'],
    input[type='search'],
    input[type='tel'],
    input[type='time'],
    input[type='url'],
    select {
      margin-top: 0;
      margin-bottom: 12px;
      height: 36px;

      &.field-error {
        border-color: red;
      }
    }

    select {
    }

    input[type='text'],
    textarea {
      display: block;
      margin-top: 0;
      padding: 9px;
      width: 100%;
      border: 1px solid #cecece;
      background: rgba(white, 0.5);
      font-weight: 300;
      font-size: 16px;

      &::-webkit-input-placeholder {
        color: #8a8a8a;
      }

      &::-moz-placeholder {
        color: #8a8a8a;
      }

      &:-moz-placeholder {
        color: #8a8a8a;
      }

      &:-ms-input-placeholder {
        color: #8a8a8a;
      }

      &:focus {
        border-color: #8a8a8a;
      }
    }

    [type="checkbox"] {
      display: inline-block;
      appearance: none;
    }

    [type="radio"] {
      display: inline-block;
      appearance: none;
    }

    textarea {
      // margin-top: 10px;
      margin-bottom: 12px;
      padding: 14px;
      font-style: normal;
      font-size: 16px;
    }

    label {
      margin-top: 0;
      font-size: 14px;
      line-height: 1.2375rem;
      font-weight: bold;

      @at-root .content-form.inline label{
        @include medium-up {
          margin-top: 12px;
        }
      }
    }

    select {
      // margin-top: 0;
      padding: 9px;
      width: 100%;
      border: none;
      // background: rgba(white, 0.5);
      background: rgba(white, 0.5) url('../images/icon-select.svg') top right / 30px 72px no-repeat;
      font-weight: 300;
      font-size: 14px;
      border: 1px solid #cecece;

      &:focus {
        border-color: #8a8a8a;
      }

      &.open {
        background-position: bottom right;
      }

      // &:focus {
      //   background-position: bottom right;
      // }
    }

    .checkbox-list,
    .radiobutton-list {
      margin: 0;
      list-style: none !important;
      text-align: left;

      li {
        position: relative;
        margin: 0 0 10px 0;

        &:last-child {
          margin-bottom: 0;
        }
      }

      input[type='checkbox'],
      input[type='radio'] {
        position: absolute;
        top: calc(50% - 6px);
        left: 0;
        transform: translateY(-50%);

        + label {
          position: relative;
          top: 50%;
          transform: translateY(-50%);
          padding-left: 26px;
          width: calc(100% - 16px);
        }
      }
    }

    input[type='checkbox'],
    input[type='radio'] {
      margin-bottom: 12px;
      width: 16px;
      height: 16px;
      border: 2px solid $mercury;
      background: white;

      &.field-error {
        border-color: red;
      }

      + label {
        display: inline-block;
        top: -1px;
        margin-bottom: 12px;
        @include fontsize(15 16);
      }

      &:checked {
        border-color: $mercury;
        // background: $monza;
        appearance: none;

        &:before {
          position: absolute;
          top: -3px;
          left: -1px;
          font-size: 15px;
          content: '\f00c';
          font-family: FontAwesome;
          font-style: normal;
          font-weight: normal;
          text-decoration: inherit;
          color: $kleinblue;
        }
      }
    }

    input[type='radio'] {
      border-radius: 50%;

      &:checked:before {
        top: 1px;
        left: 1px;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: $kleinblue;
        content: '';
      }
    }

    button,
    html input[type='button'],
    input[type='reset'],
    input[type='submit'] {
      display: inline-block;
      padding: 16px 40px;
      width: calc((100% - 50px) / 1);
      border: none;
      color: #ffffff;
      background: #0032a0;
      text-transform: uppercase;
      text-align: center;
      font-weight: 400;
      font-style: normal;
      font-size: 14px;
      cursor: pointer;
      // -webkit-appearance: button;
      &:hover,
      &:focus {
        background: $prussianblue;
      }

      &.inline {
        width: auto;
      }

      &.margin-top {
        margin-top: 20px;
      }

      &.btn-inline {
        padding-left: 0;
        padding-right: 0;
        width: auto;
      }

      &.btn-wide {
        width: 100%;
      }
    }

    .hide {
      display: none;
    }

    .col-half {
      float: left;
      padding-right: 24px;
      width: 100%;
      width: calc((100% - 24px) / 2);

      @media only screen and (max-width: 640px) {
        float: none;
        margin-right: 0;
        padding-right: 0;
        width: 100%;
      }

      &:nth-last-child() {
        padding-right: 0;
      }
    }

    .col-full {
      float: left;
      padding-right: 24px;
      width: calc((100% - 24px) / 1);

      @media only screen and (max-width: 640px) {
        padding-right: 0;
        width: 100%;
      }
    }

    .col-last {
      clear: right;
      margin-right: 0;
    }

    .pnl-textarea {
      margin-top: 9px;
      padding-bottom: 0;

      label {
        position: relative;
        top: 0;
        bottom: auto;
        margin-bottom: 9px;
      }

      .error-msg {
        top: 0;
        bottom: auto;
      }
    }

    .error-msg {
      // display: block !important;
      // position: absolute;
      // right: 0;
      // bottom: 0;
      &.agreement {
        top: 0;
      }
    }

    .validation-summary {
      margin-bottom: 28px;

      ul {
        margin: 18px 0;
        list-style: none;
      }
    }
  }
}

.g-recaptcha {
  margin-bottom: 30px;
}
