table {
  width: 100%;
  border-spacing: 0;
  font-style: normal;
  font-family: Roboto, sans-serif;

  tbody,
  thead {
    tr {
      border-spacing: 0;

      &:nth-child(odd) {
        background: #ebebeb;
      }

      &:nth-child(even) {
        background: #f5f5f5;
      }

      @media only screen and (max-width: 767px) {
        padding: 25px 0;
        border-bottom: 2px solid #ffffff;
      }

      th {
        padding: 12px 20px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        background: #595959;
        color: #ffffff;
        text-align: left;
        font-weight: 700;
        font-style: normal;
        font-size: 16px;

        &:first-child {
          border-right: 2px solid #ffffff;
        }

        &:last-child {
          border-right: none;
        }
      }

      td {
        padding: 12px 20px;
        border-right: 2px solid #ffffff;
        border-bottom: 2px solid #ffffff;
        color: #333333;
        font-size: 16px;

        @media only screen and (max-width: 767px) {
          padding: 10px 20px;
          border: none;
        }

        &:first-child {
          border-right: 2px solid #ffffff;

          @media only screen and (max-width: 767px) {
            border: none;
          }
        }

        &:last-child {
          border-right: none;
        }

        img {
          max-width: 300px;
          width: 100%;
        }
      }
    }
  }
}
