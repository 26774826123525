.content-wrapper.home-page {
  overflow: hidden;
  margin-top: 0;

  .feature {
    position: relative;
    max-height: 32.5rem;
    height: 32.5rem;
    background: #f6f6f6;

    @media only screen and (max-width: 1023px) {
      max-height: 100%;
      height: auto;
    }

    .bg {
      position: absolute;
      top: 0;
      width: calc(50% + 35px);
      height: 100%;
    }

    h2 {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      padding: 1.25rem 0.625rem;
      width: 75%;
      vertical-align: center;
      text-align: center;
      text-transform: uppercase;
      font-size: 30px;
      transform: translate(-50%,-50%);
      font-weight: 200;

      // @media only screen and (max-width: 1023px) {
      //   font-size: 1.5rem;
      // }
    }

    a {
      font-weight: 700;
    }

  }

  .heading-banner {
    position: relative;
    background: #062d89 url('/Themes/EOM/assets/dist/images/bg-heading.jpg') no-repeat center top;
    background-size: cover;
    border-bottom: 1px solid #fff;

    .heading-title {
      position: relative;
      display: inline-block;
      padding: 40px;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        padding-left: 20px;
        padding-right: 20px;
      }

      h1 {
        position: relative;
        letter-spacing: 2px;
        height: auto;
        color: #ffffff;
        text-align: center;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 30px;
        line-height: 36px;
        font-family: roboto-condensed, sans-serif;
        margin: 0;
      }
    }
  }

  .feature.headline {
    position: relative;
    margin-top: -18px;
    border-bottom: solid 2px #ffffff;
    background: url('/Themes/EOM/assets/dist/images/home-headline-bg.jpg') no-repeat center center;
    background-size: cover;
    color: #ffffff;

    .headline-container {
      position: relative;
      top: 50%;
      left: 50%;
      padding: 0 0 0 2.1875rem;
      width: 60%;
      transform: translate(-33.33%,-50%);

      @media only screen and (max-width: 1023px) {
        position: static;
        top: auto;
        left: auto;
        padding: 3.125rem 1.25rem;
        width: auto;
        transform: translate(0,0);
      }

      blockquote {
        padding: 0 0 0 2.5rem;
        border: none;
        color: #ffffff;
        font-weight: 400;
        font-style: normal;
        font-size: 1.125rem;
        font-family: Roboto,sans-serif;
        line-height: 2rem;

        @media only screen and (max-width: 1023px) {
          padding: 0;
          border: none;
          color: #ffffff;
          font-weight: 400;
        }

        &:before {
          position: absolute;
          top: 0;
          left: 0;
          width: 3px;
          height: 100%;
          border: 0;
          background: linear-gradient(to bottom,#05a8e1 0,#66ba00 100%);
          content: '';

          @media only screen and (max-width: 1023px) {
            display: none;
          }
        }

        h1 {
          font-size: 1.5rem;
        }
      }

      p {
        text-align: center;
        font-family: Roboto, sans-serif;
      }
    }
  }

  .feature.healthcare {
    display: inline-block;
    max-height: 100%;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1023px) {
      position: relative;
      display: block;
      margin-bottom: -15px;
      padding: 0 1.5625rem;
      height: auto;
      background: #f6f6f6;
    }

    .bg {
      background: url('/Themes/EOM/assets/dist/images/home-feature-healthcare-bg.jpg') no-repeat center center;
      background-size: cover;

      @media only screen and (max-width: 1023px) {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(300px - 35px);
      }
    }

    .bg.notch-right {
      left: 0;

      clip-path: polygon(0 0,calc(100% - 35px) 0,calc(100% - 40px) calc(50% - 40px),100% 50%,calc(100% - 35px) calc(50% + 40px),calc(100% - 35px) 100%,0 100%);

      @media only screen and (max-width: 1023px) {
        clip-path: polygon(0 0,100% 0,100% calc(100% - 35px),calc(50% + 40px) calc(100% - 35px),50% 100%,calc(50% - 40px) calc(100% - 35px),0 calc(100% - 35px));
      }
    }

    .heading {
      position: absolute;
      top: 50%;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        position: relative;
        margin: 0;
        padding: 0;
        max-width: 100%;
        height: calc(300px - 70px);
      }

      h2 {
        background: #f24d1d;
        color: #ffffff;
        font-family: Roboto,sans-serif;
      }
    }

    .content {
      float: right;
      flex: 0 0 50%;
      margin-bottom: 1rem;
      padding: 6.25rem 6.25rem 3.125rem 6.25rem;
      max-width: 50%;
      color: #000000;
      font-size: 1rem;
      font-family: Roboto,sans-serif;
      line-height: 2rem;

      text-rendering: optimizeLegibility;

      @media only screen and (max-width: 1023px) {
        float: none;
        padding: 3.125rem 1.25rem 3.125rem 1.25rem;
        max-width: 100%;
      }

      .healthcare-callout {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          column-count: 3;

          @media only screen and (max-width: 640px) {
            column-count: 1;
          }

          li {
            margin-bottom: 1rem;
          }
        }

        a {
          color: #0032a0;
          text-decoration: none;
        }
      }
    }
  }

  .feature.workplace-printing {
    display: inline-block;
    max-height: 100%;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1023px) {
      position: relative;
      display: block;
      margin-bottom: -15px;
      padding: 0 1.5625rem;
      height: auto;
      background: #f6f6f6;
    }

    .bg {
      background: url('/Themes/EOM/assets/dist/images/home-feature-workplace-printing-bg.jpg') no-repeat center center;
      background-size: cover;

      @media only screen and (max-width: 1023px) {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(300px - 35px);
      }
    }

    .bg.notch-left {
      right: 0;

      clip-path: polygon(35px 0,100% 0,100% 100%,35px 100%,35px 100%,35px calc(50% + 40px),0 50%,35px calc(50% - 40px));

      @media only screen and (max-width: 1023px) {
        clip-path: polygon(0 0,100% 0,100% calc(100% - 35px),calc(50% + 40px) calc(100% - 35px),50% 100%,calc(50% - 40px) calc(100% - 35px),0 calc(100% - 35px));
      }

      // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      //   /* IE10+ CSS styles go here */
      //   width: 50%;
      // }
    }

    .heading {
      position: absolute;
      top: 50%;
      right: 0;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        position: relative;
        margin: 0;
        padding: 0;
        max-width: 100%;
        height: calc(300px - 70px);
      }

      h2 {
        background: #31a59b;
        color: #ffffff;
        font-family: Roboto,sans-serif;
      }
    }

    .content {
      float: left;
      flex: 0 0 50%;
      margin-bottom: 1rem;
      padding: 6.25rem 6.25rem 3.125rem 6.25rem;
      max-width: 50%;
      color: #000000;
      font-size: 1rem;
      font-family: Roboto,sans-serif;
      line-height: 2rem;

      text-rendering: optimizeLegibility;

      @media only screen and (max-width: 1023px) {
        float: none;
        padding: 3.125rem 1.25rem 3.125rem 1.25rem;
        max-width: 100%;
      }

      .workplace-printing-callout {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 1rem;
          }
        }

        a {
          color: #0032a0;
          text-decoration: none;
        }
      }
    }
  }

  .feature.digital-workplace-printing {
    display: inline-block;
    max-height: 100%;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1023px) {
      position: relative;
      display: block;
      margin-bottom: -15px;
      padding: 0 1.5625rem;
      height: auto;
      background: #f6f6f6;
    }

    .bg {
      background: url('/Themes/EOM/assets/dist/images/home-feature-digital-workplace-bg.jpg') no-repeat center center;
      background-size: cover;

      @media only screen and (max-width: 1023px) {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(300px - 35px);
      }
    }

    .bg.notch-left {
      right: 0;

      clip-path: polygon(35px 0,100% 0,100% 100%,35px 100%,35px 100%,35px calc(50% + 40px),0 50%,35px calc(50% - 40px));

      @media only screen and (max-width: 1023px) {
        clip-path: polygon(0 0,100% 0,100% calc(100% - 35px),calc(50% + 40px) calc(100% - 35px),50% 100%,calc(50% - 40px) calc(100% - 35px),0 calc(100% - 35px));
      }

      // @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      //   /* IE10+ CSS styles go here */
      //   width: 50%;
      // }
    }

    .heading {
      position: absolute;
      top: 50%;
      right: 0;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        position: relative;
        margin: 0;
        padding: 0;
        max-width: 100%;
        height: calc(300px - 70px);
      }

      h2 {
        background: #0032a0;
        color: #ffffff;
        font-family: Roboto,sans-serif;
      }
    }

    .content {
      float: left;
      flex: 0 0 50%;
      margin-bottom: 1rem;
      padding: 6.25rem 6.25rem 3.125rem 6.25rem;
      max-width: 50%;
      color: #000000;
      font-size: 1rem;
      font-family: Roboto,sans-serif;
      line-height: 2rem;

      text-rendering: optimizeLegibility;

      @media only screen and (max-width: 1023px) {
        float: none;
        padding: 3.125rem 1.25rem 3.125rem 1.25rem;
        max-width: 100%;
      }

      .workplace-printing-callout {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 1rem;
          }
        }

        a {
          color: #0032a0;
          text-decoration: none;
        }
      }
    }
  }

  .feature.pull-printing {
    display: inline-block;
    max-height: 100%;
    width: 100%;
    height: 100%;

    @media only screen and (max-width: 1023px) {
      position: relative;
      display: block;
      margin-bottom: -15px;
      padding: 0 1.5625rem;
      height: auto;
      background: #f6f6f6;
    }

    .bg {
      background: url('/Themes/EOM/assets/dist/images/home-feature-pull-printing-bg.jpg') no-repeat center center;
      background-size: cover;

      @media only screen and (max-width: 1023px) {
        position: absolute;
        top: 0;
        width: 100%;
        height: calc(300px - 35px);
      }
    }

    .bg.notch-right {
      left: 0;

      clip-path: polygon(0 0,calc(100% - 35px) 0,calc(100% - 40px) calc(50% - 40px),100% 50%,calc(100% - 35px) calc(50% + 40px),calc(100% - 35px) 100%,0 100%);

      @media only screen and (max-width: 1023px) {
        clip-path: polygon(0 0,100% 0,100% calc(100% - 35px),calc(50% + 40px) calc(100% - 35px),50% 100%,calc(50% - 40px) calc(100% - 35px),0 calc(100% - 35px));
      }
    }

    .heading {
      position: absolute;
      top: 50%;
      flex: 0 0 50%;
      max-width: 50%;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        position: relative;
        margin: 0;
        padding: 0;
        max-width: 100%;
        height: calc(300px - 70px);
      }

      h2 {
        background: #079ed5;
        color: #ffffff;
        font-family: Roboto,sans-serif;
      }
    }

    .content {
      float: right;
      flex: 0 0 50%;
      margin-bottom: 1rem;
      padding: 6.25rem 6.25rem 3.125rem 6.25rem;
      max-width: 50%;
      color: #000000;
      font-size: 1rem;
      font-family: Roboto,sans-serif;
      line-height: 2rem;

      text-rendering: optimizeLegibility;

      @media only screen and (max-width: 1023px) {
        float: none;
        padding: 3.125rem 1.25rem 3.125rem 1.25rem;
        max-width: 100%;
      }

      .pull-printing-callout {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;

          li {
            margin-bottom: 1rem;
          }
        }

        a {
          color: #0032a0;
          text-decoration: none;
        }
      }
    }
  }

  .solutions {
    // display: flex;
    margin: 0 auto;
    padding: 4.375rem 6.25rem;
    height: auto;
    background: #e6e6e6;
    color: #575757;

    flex-flow: row wrap;

    @media only screen and (max-width: 1023px) {
      display: block;
      padding: 2.5rem 1.25rem;
    }

    .heading {
      max-width: 100%;

      h3 {
        margin: 0 0 40px 0;
        color: #333333;
        text-align: left;
        text-transform: uppercase;
        font-weight: 100;
        font-size: 30px;
        line-height: 30px;
        letter-spacing: 3px;
        font-family: roboto-condensed, sans-serif;

        // @media only screen and (max-width: 1023px) {
        //   font-size: 32px;
        //   line-height: 38px;
        // }

        // @media only screen and (max-width: 640px) {
        //   font-size: 18px;
        //   line-height: 28px;
        // }

        span {
          margin: 0 10px;
          display: inline-block;
          height: 24px;
          border-right: 2px solid #b3b3b3;

          // @media only screen and (max-width: 1023px) {
          //   font-size: 32px;
          //   height: 25px;
          // }

          // @media only screen and (max-width: 640px) {
          //   height: 15px;
          //   margin: 0 4px;
          // }
        }

        @media only screen and (max-width: 1023px) {
          margin: 0 0 1.25rem 0;
          padding-right: 0.625rem;
          padding-left: 0.625rem;
        }
      }
    }

    .content {
      display: inline-flex;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        display: block;
        padding-right: 0.625rem;
        padding-left: 0.625rem;
        width: 100%;
      }

      ul {
        margin: 0;
        padding-left: .25rem;
        width: 100%;
        border-left: solid 2px #d6d6d6;
        list-style: none;
        list-style-type: none;
        font-family: Roboto,sans-serif;

        align-items: center;
        flex-wrap: wrap;

        li {
          flex: 0 0 100%;
          max-width: 100%;

          a {
            display: block;
            margin-bottom: 0;
            padding: 0 1rem 1rem 1rem;
            color: #575757;
            text-decoration: none;
            font-weight: normal;
            line-height: 1;

            align-items: flex-start;
            flex-flow: row nowrap;
            justify-content: flex-start;

            &:hover {
              text-decoration: underline;
            }
          }

          &:last-of-type {
            a {
              padding-bottom: 0;
            }
          }
        }
      }
    }
  }
}
