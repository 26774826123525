.sidebar.right {
  order: 3 !important;

  @media only screen and (max-width: 1023px) {
    flex: 0 0 100%;
    max-width: 100%;
  }
}

.sidebar.right,
.sidebar.left {
  .widgets {
    position: relative;
    margin: 0 0 2.1875rem 0;
    padding: 0;
    color: #414141;
    font-size: 0.85rem;
    line-height: 1.6rem;

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      width: 5px;
      height: 100%;
      content: '';
    }

    .widgets {
      padding: 0 1.25rem;

      &:after {
        position: absolute;
        top: 35px;
        left: 5px;
        display: block;
        width: 100%;
        border-top: 1px solid #d5d5d5;
        content: '';
      }

      h2 {
        color: black !important;
      }
    }

    .learn-more {
      &:before {
        background: #dadada;
      }

      ul {
        margin: 0.3125rem 0;
        padding: 0;
        line-height: 1.6rem;

        li.doc {
          position: relative;
          border-bottom: 1px dashed #9c9c9c;
          list-style: none;
          font-size: 0.875rem;

          a {
            color: #0032a0;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }

        li:nth-child(n+4) {
          display: none;
        }
      }

      .more a {
        color: #737373;
        font-weight: 700;
      }
    }

    .recent-post {
      &:before {
        background: #0032a0;
      }

      ul {
        margin: 0.3125rem 0;
        padding: 0;
        list-style: none;

        li.post {
          clear: both;
          margin-bottom: 0.5rem;
          padding: 0.3125rem 0;

          h3 {
            display: inline-block;
            margin-top: 0;
            text-transform: none;
            font-weight: 400;
            font-size: 14px;
          }

          a {
            color: #414141;
            text-decoration: none;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }

    .recent-events {
      &:before {
        background: #8fc50d;
      }

      .event-title {
        font-style: italic;

        a {
          color: #414141;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    .recent-testimonials {
      blockquote {
        margin: 0 0 0.625rem 0;
        padding: 0;
        border: none;
        color: #595959;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;

        &:before {
          content: '';
          display: none;
        }
    
        &:after {
          content: '';
          display: none;
        }

        cite {
          display: block;
          color: #737373;
          font-style: italic;
          font-size: 12px;
        }
      }

      &:before {
        background: #06a7e2;
      }
    }

    .more {
      margin-top: 0;
      font-size: 0.9rem;

      a {
        color: #737373;
        text-decoration: none;
        font-weight: 700;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }

  .btn-request-demo,
  .btn-request-news {
    margin-bottom: 1.875rem;
    height: 50px;
    border-radius: 5px;

// border-left: 5px solid #404040;
    background: url(/themes/eom/assets/dist/images/request-demo.png) no-repeat;
    background-color: #013fa7;
    background-position: right;

    a {
      position: relative;
      display: block;
      padding: 1.125rem 0 1.125rem 1rem;

// border-bottom: 1px solid #d5d5d5;
      color: white;
      text-decoration: none;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 0.9375rem;
      line-height: 0.9375rem;

      &:after {
        position: absolute;
        right: 20px;
        color: #cedb08;
        content: '\f0a9';
        text-decoration: inherit;
        font-weight: 900;
        font-style: normal;
        font-size: 18px;
        font-family: FontAwesome;
      }
    }
  }

  @media screen and (max-width: 1200px) and (min-width: 1024px) {
    .btn-request-news a {
      font-size: 12px;
    }
  }
}
