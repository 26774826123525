.rotating-banner {
  border-bottom: 1px solid #fff;

  .cycle-prev, 
  .cycle-next {
    position: absolute;
    top: 0;
    display: flex;
    align-items: center;
    width: 10%;
    opacity: 0.4;
    filter: alpha(opacity=0);
    z-index: 800;
    height: 100%;
    cursor: pointer;

    @media only screen and (min-width: 768px) {
      width: 5%;
    }

    &:hover {
      opacity: 0.7;
    }

    &::before,
    &::after {
      position: absolute;
      font-family: FontAwesome;
      color: #ffffff;
      font-size: 18px;

      @media only screen and (min-width: 486px) {
        font-size: 30px;
      }
    }
  }

  .cycle-next {
    right: 0;

    &::before {
      content: '\f054';
      right: 10px;

      @media only screen and (min-width: 486px) {
        right: 20px;
      }
    }
  }

  .cycle-prev {
    left: 0;

    &::before {
      content: '\f053';
      left: 10px;

      @media only screen and (min-width: 486px) {
        left: 20px;
      }
    }
  }


  .slide {
    width: 100%; 
    height: auto;


    a {
      display: block;
      position: relative;

      img {
        width: 100%;
        display: block;
      }

    }
  
  }
}
