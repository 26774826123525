.subpage.landing-page {
  header.primary,
  section.primary {
    position: relative;
    margin: 0;
    max-width: 100%;
    width: 100%;
    padding-bottom: 0;

    @media (min-width: 1024px) {
      padding-bottom: 1.75rem;

    }

    .contents {
      margin: 0;
      max-width: 100%;
    }

    &.landing-page {
      .contents {
        display: flex;
        margin-right: auto;
        margin-left: auto;
        max-width: 75rem;

        justify-content: space-between;

        @media (max-width: 1024px) {
          display: block;
        }
      }

      .title {
        position: relative;

        h1 {
          color: #0032a0;
          font-weight: 400;
          font-family: Roboto,sans-serif;
        }
      }

      &.video {
        display: block;
        padding: 0;
        height: 450px;

        .headline.feature.new {
          position: relative;
          margin-top: 0;
          padding-bottom: 0;
          width: 100%;
          height: 100%;
        }

        .contents {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          margin: auto;
          max-width: 600px;

          align-items: center;
          justify-content: center;

          .flex-container {
            .flex-video {
              padding-top: 0;
            }
          }
        }
      }
    }

    div.nav-header {
      top: 0;
      flex: 0 0 85%;
      max-width: 85%;

      .nav-primary {
        border-top: none;
      }
    }
  }

  .headline.feature.new {
    position: relative;
    display: block;
    margin-top: 10px;
    padding-bottom: 35%;
    height: 0;
    border-bottom: solid 2px #ffffff;
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
    color: #ffffff;
  }

  .content-wrapper.full {
    padding: 3.125rem 0 10.9375rem 0;

    @media only screen and (max-width: 1023px) {
      margin: 0;
    }
  }

  .content-wrapper.two-column {
    display: flex;
    margin-top: 0;
    padding: 3.125rem 0 10.9375rem 0;

    section.main {
      flex: 0 0 75%;
      margin: 0;
      margin-left: 0;
      padding-right: 0.9375rem;
      padding-left: 35px;
      max-width: 75rem;
      max-width: 75%;

      order: 2;

      @media only screen and (max-width: 1023px) {
        flex: 0 0 100%;
        margin: 0;
        max-width: 100%;

        order: 1;
      }
    }
  }

  &.narrow .headline.feature.new {
    padding-bottom: 15%;
  }

  blockquote {
    position: relative;
    margin-left: 0;
    margin-right: 0;

    &:before {
      content: '';
      background-image: url(/themes/eom/assets/dist/images/icons/icon-quotes.svg);
      position: absolute;
      top: 6px;
      left: 0;
      background-size: 100%;
      background-repeat: no-repeat;
      background-position: center;
      width: 65px;
      height: 50px;
    }

    &:after {
      content: '';
      position: absolute;
      top: 57px;
      bottom: 9px;
      left: 60px;
      width: 5px;
      background-color: #e6e6e6;
    }

    p {
      margin-left: 90px;
      font-size: 22px;
      color: #595959;

      span {
        text-transform: uppercase;
        font-size: 14px;
        color: #333333;
        display: block;
        margin-top: 8px;
      }
    }

    &.bground {
      background-color: #e6e6e6;
      padding: 0;

      &:before {
        content: '';
        top: 29px;
        left: 23px;
      }

      &:after {
        content: '';
        display: none;
      }

      p {
        background-color: #f2f2f2;
        margin-top: 0;
        margin-bottom: 0;
        padding: 20px 25px;
        border-left: 2px solid #fff;
        margin-left: 112px;
      }
    }
  }

  .widgets {
    blockquote {
      &:before {
        content: '';
        width: 35px;
        height: 35px;
        top: 2px;
      }

      &:after {
        content: '';
        top: 35px;
        left: 30px;
        bottom: 6px;
      }

      p {
        margin-left: 60px;
        font-size: 18px;
        color: #333333;
      }

      &.bground {
        &:before {
          content: '';
          left: 12px;
          top: 21px;
        }

        p {
          padding: 20px 15px;
        }
      }
    }
  }
}

.subpage.landing-page.lp-three-col,
.subpage.landing-page.lp-two-col {

  header.primary{
    padding: 2rem 1.75rem 1.75rem 1.75rem;

    .contents {
      max-width: 100%;
    }
  }
  
  h2 {
    margin-top: 15px;
  }
  
  .headline.feature.new {
    padding-bottom: 20%;
  }

  .container .content-wrapper {
    margin-top: 30px;

    &.full {
      @include large-up {
        max-width: 100vw;
      }
    }

    &.three-col section.main {      
      padding-left: .9375rem;
      padding-right: .9375rem;

      @include large-up {
        padding-left: 80px;
        padding-right: 80px;
      }
    }

    &.two-column {
      section.main {
        padding-left: .9375rem;

        @include large-up {
          padding-left: 15px;
          padding-right: 80px;
        }

        @media (min-width: 1600px) {
          flex: 0 0 65%;
        }
      }

      .sidebar {
        padding-left: .9375rem;
        padding-right: .9375rem;
        @include large-up {
          padding-left: 0;
          padding-right: 15px;
          flex: 0 0 25%;
          max-width: 25%;
        }

        @media (min-width: 1600px) {
          flex: 0 0 35%;
          max-width: 35%;
        }
      }
    }
  }
}

.subpage.landing-page.lp-three-col {
  .container {
    @include large-up {
      padding-left: 30px;
      padding-right: 30px;

    }
  }
}