footer.primary {
  position: relative;
  padding: 2.5rem 0 3.5rem;
  height: auto;
  background-color: #575759;

  .footer-content {
    font-weight: 400;
    font-style: normal;
    font-family: Roboto, sans-serif;

    ul.social-nav {
      position: relative;
      top: -10px;
      float: left;
      margin: 0;
      padding-left: 80px;

      @media only screen and (max-width: 1023px) {
        position: relative;
        top: 0;
        display: block;
        float: none;
        padding-left: 40px;
      }

      li {
        display: inline-block;
        margin-right: 1.875rem;
        list-style: none;

        a {
          display: block;
          color: #ffffff;
          font-size: 1.75rem;
        }

        a.icon-eom-blog {
          position: relative;
          bottom: -0.5rem;
          width: 1.75rem;
          height: 1.75rem;
          background: url('/Themes/EOM/assets/dist/images/icon-eom-blog.svg') center center no-repeat;
          background-size: contain;
        }
      }
    }

    ul.footer-nav {
      position: relative;
      top: 0;
      float: right;
      margin: 0;
      padding-right: 80px;

      @media only screen and (max-width: 1023px) {
        position: relative;
        top: 0;
        display: block;
        float: none;
        padding-top: 30px;
        padding-left: 40px;
      }

      li {
        display: inline-block;
        margin-right: 1.25rem;
        list-style: none;
        font-size: 0.75rem;

        @media only screen and (max-width: 1023px) {
          display: block;
          padding: 10px 0;
        }

        a {
          border-bottom: 1px solid  #ffffff;
          color: #ffffff;
          text-decoration: none;
        }
      }
    }
  }

  a.scroll-to-top {
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: 100;
    width: 80px;
    height: 80px;
    border-radius: 80px 0 0 0;
    background-color: #06a7e2;
    text-align: center;
    opacity: 0;
    cursor: pointer;
    transition: all 0.4s ease-in-out 0s;

    &:before {
      position: absolute;
      top: 45%;
      left: 45%;
      display: block;
      color: white;
      content: '\f062';
      font-size: 24px;
      font-family: FontAwesome;
      ;
    }
  }
}
