.locations {
  margin: 0;

  @media only screen and (min-width: 640px) {
    margin: 0 -20px;

    > div {
      display: flex;
      flex-wrap: wrap;
    }
  }

  h2 {
    padding: 0 20px;
  }

  > h3,
  > h4 {
    padding-left: 20px;
  }

  .location-item {
    display: block;
    position: relative;
    margin-bottom: 30px;
    width: 100%;
    vertical-align: top;

    @media only screen and (min-width: 640px) {
      display: inline-block;
      width: 50%;
      padding: 0 20px;
    }

    .location-content {
      padding: 10px 10px 39px 10px;
      border: 1px solid rgb(213, 213, 213);
      border-radius: 10px;
      height: 100%;

      @media only screen and (min-width: 640px) {
        padding: 20px 20px 39px 20px;
      }

      .location-name {
        margin: 0;
      }

      .location-address {
        display: block;

        span {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      span.product {
        display: inline-block;
        margin: 0 20px 10px 0;
        background-position: center center;
        background-size: contain;
        background-repeat: no-repeat;

        &:last-child {
          margin-right: 0;
        }

        &.drivve-image {
          width: 35px;
          height: 35px;
          background-image: url('/themes/eom/assets/dist/images/Drivve Image icon.png');
        }

        &.drivve-dm {
          width: 35px;
          height: 35px;
          background-image: url('/themes/eom/assets/dist/images/Drivve DM icon.png');
        }

        &.drivve-print {
          width: 35px;
          height: 35px;
          background-image: url('/themes/eom/assets/dist/images/Drivve Print icon.png');
        }
      }
    }

    .view-link {
      position: absolute;
      bottom: 1px;
      left: 1px;
      padding: 10px 20px;
      width: calc(100% - 2px);
      height: 38px;
      font-weight: 700;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #f6f6f6;

      @media only screen and (min-width: 640px) {
        left: 21px;
        width: calc(100% - 42px);
      }
    }
  }

  .location-item-featured {
    position: relative;
    margin-bottom: 30px;
    border: 1px solid rgb(213, 213, 213);
    border-radius: 10px;

    @media only screen and (min-width: 640px) {
      display: flex;
      left: 20px;
      padding-bottom: 38px;
      width: calc(100% - 40px);
    }

    .location-image {
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      overflow: hidden;

      @media only screen and (min-width: 640px) {
        border-top-right-radius: 0;
        width: 60%;
      }

      img {
        display: block;
      }
    }

    .location-content {
      position: relative;
      padding: 10px 10px 39px 10px;

      @media only screen and (min-width: 640px) {
        padding: 20px;
        width: 40%;
      }
    }

    .view-link {
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 10px 20px;
      width: 100%;
      height: 38px;
      font-weight: 700;
      border-bottom-right-radius: 10px;
      border-bottom-left-radius: 10px;
      background: #f6f6f6;

      @media only screen and (min-width: 640px) {
        // left: 21px;
        // width: calc(100% - 42px);
      }
    }
  }
}

.location-detail {
  position: relative;
  width: 100%;
  margin-bottom: 20px;
  padding: 10px 10px 39px 10px;
  border: 1px solid rgb(213, 213, 213);
  border-radius: 10px;

  @media only screen and (min-width: 640px) {
    padding: 20px 20px 39px 20px;
  }

  @media only screen and (min-width: 1024px) {
    display: flex;
    padding: 0 0 38px 0;
  }

  .location-map {
    position: relative;
    top: -10px;
    left: -10px;
    width: calc(100% + 20px);
    height: 400px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    overflow: hidden;

    @media only screen and (min-width: 640px) {
      left: -20px;
      top: -20px;
      width: calc(100% + 40px);
    }

    @media only screen and (min-width: 1024px) {
      left: 0;
      top: 0;
      width: 70%;
    }

    .google-map {
      height: 400px;
    }
  }

  .location-content {
    @media only screen and (min-width: 1024px) {
      padding: 15px;
      width: 30%;
    }
  }

  .back-link {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px 20px;
    width: 100%;
    height: 38px;
    font-weight: 700;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    background: #f6f6f6;

    @media only screen and (min-width: 640px) {
      // left: 21px;
      // width: calc(100% - 42px);
    }
  }
}

.location-description {
  margin-bottom: 30px;
  overflow: hidden;

  p.drivve-image,
  p.drivve-dm,
  p.drivve-print {
    float: right;
    display: inline-block;
    margin: 0 5px 10px 5px;
    background-position: center center;
    background-size: contain;
    background-repeat: no-repeat;
    text-indent: -9999rem;
  }

  p.drivve-image {
    v: url('/themes/eom/assets/dist/images/Drivve Image icon.png');
  }

  p.drivve-dm {
    width: 35px;
    height: 35px;
    background-image: url('/themes/eom/assets/dist/images/Drivve DM icon.png');
  }

  p.drivve-print {
    width: 35px;
    height: 35px;
    background-image: url('/themes/eom/assets/dist/images/Drivve Print icon.png');
  }
}