.clickdform.mainDiv {
  overflow: hidden;
  font-family: Roboto,sans-serif !important;
}

#clickdimensionsForm {
  margin: auto !important;
  max-width: 100% !important;

  @media (max-width:768px) {
    max-width: 100% !important;
  }

  tr:nth-child(odd) {
    background-color: transparent !important;
  }

  .captcha {
    padding: 25px 0;
  }

  #optintext {
    margin-left: 0 !important;
  }
}

.responsiveRow {
  display: flex;
  font-size: inherit;
  font-family: Roboto,sans-serif !important;

// min-height: 50px;

  flex-wrap: wrap;
}

.emptyCell {
  display: none;
}

.responsiveCell {
  text-align: left !important;

  div.alignBottom,
  div.alignTop {
    display: inline-block !important;
  }
}

.responsiveCellSize1 {
  flex: 1;
  margin: 0 10px;
  min-width: 0;
  width: 50%;

  span {
    font-family: Roboto,sans-serif !important;
  }

  p {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.responsiveCellSize2 {
  margin: 0 10px;
  min-width: 0;
  width: 100%;
}

.responsiveCellSize3 {
  margin: 0 10px;
  min-width: 0;

  p {
    margin-left: 0px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
  }
}

.clickdform {
  font-family: inherit;

  input[type='text'],
  input[type='password'],
  input[type='date'],
  input[type='datetime'],
  input[type='datetime-local'],
  input[type='month'],
  input[type='week'],
  input[type='email'],
  input[type='number'],
  input[type='search'],
  input[type='tel'],
  input[type='time'],
  input[type='url'],
  input[type='radio'],
  textarea,
  select {
    margin: 0 0 1rem !important;
    padding: 0.5rem !important;
    min-height: 0 !important;
    height: 2.4375rem !important;
    border: 1px solid #cacaca !important;
    border-radius: 0.3125rem !important;
    background: #fefefe !important;
    box-shadow: inset 0 1px 2px rgba(10,10,10,0.1) !important;
    color: #0a0a0a !important;
    font-size: 1rem !important;
    font-family: inherit !important;
    transition: box-shadow 0.5s,border-color 0.25s ease-in-out !important;

    &:active,
    &:focus {
      outline: 0;
      border: 1px solid #8a8a8a !important;
      background: #fefefe !important;
      box-shadow: 0 0 5px #cacaca !important;
      transition: box-shadow 0.5s,border-color 0.25s ease-in-out !important;
    }

    &[disabled] {
      border-color: palette(gray, 6);
      background: palette(gray, 6);
    }
  }

  input[type='checkbox'],
  input[type='radio'] {
    margin: 4px 10px 0 0 !important;
    height: auto !important;
    border: none !important;
    background: transparent !important;
    box-shadow: none !important;
    transition: none !important;
  }

  input[type='checkbox'] {
    appearance: checkbox;
  }

  input[type='radio'] {
    appearance: radio;
  }

  #btnSubmit {
    display: block !important;
    margin: 0 0 1rem 0 !important;
    padding: 0.75em 2em !important;
    border: 1px solid transparent !important;
    border-radius: 0.3125rem !important;
    background: #0032a0 !important;
    color: #fefefe !important;
    vertical-align: middle !important;
    text-align: centeer !important;
    text-transform: uppercase !important;
    letter-spacing: 0.0625rem !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    font-family: Roboto,sans-serif !important;
    line-height: 1 !important;
    cursor: pointer !important;
    transition: background-color 0.25s ease-out,color 0.25s ease-out !important;

    &:active,
    &:hover {
      background: #002880 !important;
      color: #fefefe !important;
      text-decoration: none !important;
    }
  }

  .minSize1,
  .minSize2,
  .maxSize1,
  .maxSize2 {
    min-width: 100%  !important;
    min-height: 20px !important;
    width: 100% !important;
    background: white !important;
    font-size: 0.875rem !important;
    font-family: Roboto,sans-serif !important;

    b,
    strong,
    span {
      font-weight: normal !important;
      font-size: 14px !important;
      font-family: inherit !important;
      font-family: Roboto,sans-serif !important;
    }
  }

  .maxSize3,
  .minSize3 {
    min-width: 0 !important;
    max-width: 550px !important;
    width: 100% !important;
    font-size: 14px !important;
    font-family: Roboto,sans-serif !important;

    span {
      font-size: 14px !important;
      font-family: Roboto,sans-serif !important;
      display: block;
    }
  }
}

.QapTcha {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.widgets {
  .clickdform {
    &.mainDiv {
      float: none;
    }

    .responsiveRow {
      width: 95%;
      font-size: inherit;

      flex-wrap: nowrap;
      justify-content: space-between;

      p {
        margin-bottom: 0;
      }

      .requiredStar {
        font-size: 16px !important;
        color: #E60000 !important;
      }

      .responsiveCell {
        display: block;
        margin: 0 3px;
        min-width: 50%;
        width: 50%;
      }

      .responsiveCellSize3 {
        min-width: auto;
        width: auto;

        span {
          color: #414141 !important;
          font-size: 14px !important;
          font-family: Roboto,sans-serif !important;
          line-height: normal;
        }
      }
    }
  }
}
