body.print {
  .sidebar {
    padding-left: 30px;
    margin-left: 80px;
    flex: 1;
    border-left: 2px solid #e6e6e6;

    p {
      font: 400 16px/28px 'Roboto', sans-serif;
      margin-bottom: 50px;
    }

    h2, h3, {
      margin-bottom: 5px;
    }

    a {
      color: #0032a0;

      &:hover {
        text-decoration: underline;
      }
    }

    ul {
      list-style-type: none;
      line-height: rem-calc(35px);
    }

    @media only screen and (max-width: 1024px) {
      padding: 70px 0 100px;
      margin-left: 0;
      border-top: 2px solid #e6e6e6;
      border-left: none;
    }

    .widgets {
      h2 {
        font-size: 20px;
      }
      h3 {
        font-size: 16px;
        a {
          font-weight: 400;
        }
      }
    }
  }
}
