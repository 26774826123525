html,
body {
  position: relative;
  -webkit-overflow-scrolling: touch;
  height: 100%;
  background: white;
  font-size: 100%;
}

.clearfix:after {
  display: table;
  clear: both;
  content: '';
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

a {
  color: blue;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

  &:visited {
    color: blue;
  }

  &:hover,
  &:focus {
    color: purple;
    text-decoration: underline;
  }
}

.btn-primary,
a.btn-primary,
button.btn-primary,
.content .btn-primary {
  display: inline-block;
  padding: 16px 40px;
  width: calc((100% - 50px) / 1);
  border: none;
  color: #ffffff;
  background: $kleinblue;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  // -webkit-appearance: button;
  &:visited {
    color: #ffffff;
    background: $kleinblue;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: #ffffff;
    background: darken($kleinblue, 10%);
    text-decoration: none;
  }

  &.btn-inline {
    width: auto;
  }

  &.btn-margin-top {
    margin-top: 20px;
  }

  &.btn-full {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-small {
    padding: 8px 10px;
  }

  &.btn-wide {
    width: 100%;
  }
}

.btn-secondary,
a.btn-secondary,
button.btn-secondary,
.content .btn-secondary,
 {
  display: inline-block;
  padding: 16px 40px;
  width: calc((100% - 50px) / 1);
  border: none;
  color: #ffffff;
  background: $pacificblue;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  // -webkit-appearance: button;
  &:visited {
    color: #ffffff;
    background: $pacificblue;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: #ffffff;
    background: darken($prussianblue, 10%);
    text-decoration: none;
  }

  &.btn-inline {
    width: auto;
  }

  &.btn-margin-top {
    margin-top: 20px;
  }

  &.btn-full {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-small {
    padding: 8px 10px;
  }
}

.btn-cancel,
a.btn-cancel,
button.btn-cancel,
.content .btn-cancel {
  display: inline-block;
  padding: 16px 0;
  width: calc((100% - 50px) / 1);
  border: none;
  color: red;
  background: transparent;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 400;
  font-style: normal;
  font-size: 14px;
  cursor: pointer;
  // -webkit-appearance: button;
  &:visited {
    color: red;
    background: transparent;
    text-decoration: none;
  }

  &:hover,
  &:focus {
    color: $mineshaft;
    background: transparent;
    text-decoration: none;
  }

  &.btn-inline {
    width: auto;
  }

  &.btn-margin-top {
    margin-top: 20px;
  }

  &.btn-full {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

  &.btn-small {
    padding: 8px 10px;
  }
}

input {
  appearance: none;
}

.element-invisible {
  position: absolute !important;
  overflow: hidden;
  clip: rect(1px 1px 1px 1px);
  width: 1px;
  height: 1px;
}

.clear {
  zoom: 1;

  &:before,
  &:after {
    display: table;
    content: '';
  }

  &:after {
    clear: both;
  }
}
