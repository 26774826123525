.modal {
  .close-modal {
    // position: relative;
    // position: absolute;
    // top: auto;
    // left: 0;
    // right: 0;
    // bottom: 48px;
    // right: -19px;
    // float: left;
    // margin-bottom: -48px;
    // margin: 0 auto;
    // padding: 16px 40px;
    padding: 10px 24px;
    // width: 122px;
    width: auto;
    top: -36px;
    right: 0;
    border: none;
    color: #ffffff;
    background: #0097c9;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: 400;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    cursor: pointer;
    border-radius: 0;

    // .fa-close {
    //   position: relative;
    //   top: -3px;
    //   left: -1px;

    //   &:before {
    //     color: white;
    //   }
    // }

    &:hover,
    &:focus {
      color: #ffffff;
      background: #000c26;
      text-decoration: none;
    }
  }
}