.print {
  .header-container {
    margin: 0 auto;
    max-width: 1280px;
  }

  header {
    //   display: inline-flex;
    display: flex;
    overflow: hidden;
    margin: 0 auto;
    padding: 45px 0;
    max-width: 1280px;
    width: 100%;
    height: 160px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);

    flex-wrap: nowrap;
    justify-content: space-between;
  }

  .logo-container {
    margin: 30px auto;
    height: 65px;
    text-align: center;

    @media only screen and (max-width: 1024px) {
      display: block !important;
      float: none !important;
      width: 100%;
    }

    a {
      display: inline-block;
      margin: 0 auto;
      width: 185px;
      height: 65px;
    }
  }

  .header-links {
    position: relative;
    display: inline-block;
    height: 100%;
    color: #ffffff;
    text-align: right;
    font-family: Roboto,sans-serif;

    @media only screen and (max-width: 1024px) {
      display: block;
      width: 100%;
    }

    .links {
      display: flex;

      @media only screen and (max-width: 1024px) {
        display: block;
        width: 100%;
        text-align: center;
      }

      a {
        margin: 0 5px;
        color: #ffffff;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .cta {
      @media (max-width: 1024px) {
        display: block;
        margin: 20px 0 10px;
      }
    }
  }

  .header-right {
    position: relative;
    display: inline-block;
    float: right;

// width: 80%;
    height: 100%;
    text-align: right;
  }

  .menu-btn {
    display: flex;
    flex-direction: row;
    float: right;
    width: fit-content;
    opacity: 0;
    animation: fadeIn 2s forwards;
  }

  .menu-printer-icn {
    margin: 0 15px 0 0;
    width: 45px;
    opacity: 0.5;
  }

  .menu-btn:hover .menu-printer-icn {
    opacity: 1;
    transition: 0.75s;
  }
}

@media only screen and (min-width: 1025px) {
  .print .logo-container {
    position: relative;
    display: inline-block;
    float: left;
    margin: 0;
    width: 185px;
    height: 65px;
    text-align: left;
  }
}

// Styles for resolutions 1366px to 1280px
@media only screen and (max-width: 1366px) {
  .print {
    .header-container {
      max-width: 100%;
    }

    header {
      padding: 0 50px;
    }
  }
}

@media only screen and (max-width: 1024px) {
  .print {
    header {
      display: block;
      padding: 0;
      width: 100%;
      height: auto;
    }

    .header-right {
      position: relative;
      width: 100%;
      height: 65px;
      border-top: 2px solid rgba(255,255,255,0.2);
      background: linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.15));
      text-align: center;
    }

    .menu-btn {
      float: none;
      margin: 0 auto;
      height: 45px;
      opacity: 1;
    }
  }
}

// Animations
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

body.print {
  .logo-container {
    position: relative;
    display: inline-block;
    float: left;
    width: 20%;
    height: 100%;
  }

  .logo {
    position: relative;
    top: 50%;
    display: inline;
    min-width: 200px;
    max-width: 225px;
    transform: translateY(-50%);
  }

  @media only screen and (max-width: 1024px) {
    .logo-container {
      // height: 150px;
      margin: 0 auto;
      width: 225px;
    }
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    -ms-interpolation-mode: bicubic;
  }

  // Set images in homepage buttons to 0 by default
  .hp-btn img {
    opacity: 0;
  }

  // Set images in homepage buttons to visible on hover
  .hp-btn:hover img {
    opacity: 1;
  }

  .sp-icn-container img {
    float: right;

// height: 100%;
    margin-top: 100px;
    opacity: 0;
    animation: moveUp 0.9s forwards, fadeIn 0.9s forwards;
    animation-delay: 0.9s;
  }
}

// Style for subpage icon container animation
@keyframes moveUp {
  from {
    margin-top: 100px;
  }

  to {
    margin-top: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


