.cs-calc {
  position: relative;
  display: flex;
  width: 100%;

  flex-wrap: wrap;

  .calculator-fields {
    margin-right: 25px;
    width: 50%;

    .field {
      margin: 0 0 1rem;

      span {
        display: block;
        float: none;
        margin: 0 0 0.3125rem;
        padding: 0;
        width: 100%;
        text-align: left;
        font-weight: 700;
        font-size: 0.9375rem;
        line-height: 1.0625rem;
      }

      [type=color],
      [type=date],
      [type=datetime-local],
      [type=datetime],
      [type=email],
      [type=month],
      [type=number],
      [type=password],
      [type=search],
      [type=tel],
      [type=text],
      [type=time],
      [type=url],
      [type=week],
      textarea {
        display: block;
        box-sizing: border-box;
        margin: 0 0 1rem;
        padding: 0.5rem;
        width: 100%;
        height: 2.4375rem;
        border: 1px solid #cacaca;
        border-radius: 0.3125rem;
        background-color: #fefefe;
        box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
        color: #0a0a0a;
        font-size: 1rem;
        font-family: inherit;
        transition: box-shadow 0.5s,border-color 0.25s ease-in-out;
      }

      .button {
        margin: 0 0 1rem 0;
        padding: 0.75em 2em;
        border: 1px solid transparent;
        border-radius: 0.3125rem;
        background-color: #002473;
        color: #fefefe;
        vertical-align: middle;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        font-size: 0.75rem;
        font-family: Roboto,sans-serif;
        cursor: pointer;
        transition: background-color 0.25s ease-out,color 0.25s ease-out;
      }
    }
  }

  .cs-calc-numbers {
    padding: 0 0.9375rem 0.75rem;
    width: 45%;
    background-color: #f5f5f5;

    .field {
      margin: 1rem 0;

      span {
        display: block;
        margin-left: 0.75rem;
        color: #0a0a0a;
        font-size: 0.875rem;
        line-height: 1.8;

        &:nth-of-type(odd) {
          font-weight: 700;
        }

        &:nth-of-type(even) {
          font-weight: 400;
        }
      }
    }
  }

  .cs-calc-results {
    flex: 0 0 100%;
    margin-bottom: 30px;
    max-width: 100%;
    width: 100%;

    ul {
      margin: 0.813rem 0 0!important;
      padding-top: 1.813rem;
      border-top: 1px solid #d5d5d5;

      li {
        display: inline-block;
        margin-bottom: 0.9375rem;
        width: 49%;
        border-left: 5px solid #6abcb3;

        span {
          display: block;
          margin-left: 0.75rem;
          color: #0a0a0a;
          font-size: 0.875rem;
          line-height: 1.8;

          &:nth-of-type(odd) {
            font-weight: 700;
          }

          &:nth-of-type(even) {
            font-weight: 400;
          }
        }
      }
    }
  }
}
