.two-column,
.three-column,
.four-column,
.five-column,
.six-column {
  list-style: none;
  margin: 0;
  padding: 0;
  @include medium-up {
    display: flex;
    flex-wrap: wrap;
    margin: 0 (- $gutter / 2);
  }

  .col {
    width: 100%;
  }
}

.two-column .col {
  @include medium-up {
    // width: 50%;
    flex: 1;
    padding: 0 ($gutter / 2) ($gutter / 2) ($gutter / 2);
    margin-right: 40px;

    &:last-child {
      margin-right: 0;
    }
  }

  img {
    // width: 100%;
    margin-bottom: 20px;
  }
}

.three-column .col {
  @include medium-up {
    width: 50%;
    padding: 0 ($gutter / 2) ($gutter / 2) ($gutter / 2);
  }
  @include large-up {
    width: 33.3%;
  }
}

.four-column .col {
  @include medium-up {
    width: 50%;
    padding: 0 ($gutter / 2) ($gutter / 2) ($gutter / 2);
  }
  @include large-up {
    width: 25%;
  }
}

.five-column .col {
  @include medium-up {
    width: 50%;
    padding: 0 ($gutter / 2) ($gutter / 2) ($gutter / 2);
  }
  @include large-up {
    width: 20%;
  }
}

.six-column .col {
  @include medium-up {
    width: 50%;
    padding: 0 15px 15px 15px;
  }
  @include large-up {
    width: 20%;
  }
  @include xlarge-up {
    width: 16.66666666666667%;
  }
}