.subscribe.widget {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;
  display: none;
  max-width: 100%;
  width: 100vw;
  height: 100vh;
  background: rgba(43, 46, 56, 0.9);

  .content {
    position: relative;
    top: 25%;
    top: 15%;
    z-index: 1000;
    margin: 0 auto;
    max-width: 400px;
    width: 100%;
    border-radius: 5px;
    background: #ffffff;

    .heading {
      padding: 45px 40px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      background: #f2f2f2;

      h2 {
        margin: 0;
        color: #0032a0;
        font-size: 18px;

        &:before {
          position: relative;
          top: -1px;
          padding-right: 10px;
          content: '\f0e0';
          font-size: 18px;
          font-family: FontAwesome;
        }
      }
    }

    .clickdform.mainDiv {
      padding: 35px 40px !important;
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      background: white;

      .responsiveCellSize1,
      .responsiveCellSize3 {
        margin: 0;
        min-width: 100%;
        width: 100%;
      }
    }

    .form {
      padding: 35px 40px;

      label {
        display: block;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 15px;
      }

      input {
        display: block;
        padding: 8px;
        width: 100%;
        border: 1px solid #d5d5d5;
      }

      .button {
        margin: 10px 0;
        padding: 7px 12px;
        width: auto;
        border-color: #0032a0;
        border-radius: 5px;
        background: #0032a0;
        color: white;
        font-weight: 700;
        font-size: 14px;
        cursor: pointer;
      }
    }

    .blurb {
      padding: 0 40px 10px;
      border-top: 2px solid #f2f2f2;
      font-size: 14px;

      a {
        font-weight: 700;
      }
    }

    .close {
      position: absolute;
      top: 0;
      right: 0;
      display: block;
      overflow: visible;
      margin: 0;
      padding: 0;
      width: 35px;
      height: 35px;
      outline: 0;
      border: 0;
      color: #95979c;
      text-decoration: none;
      cursor: pointer;
      transition: color 0.2s;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 35px;
        content: '\00d7';
        text-align: center;
        font-size: 25px;
        line-height: 35px;
      }
    }
  }
}

.widget {
  .subscribe {
    .clickdform.mainDiv {
      padding: 0 !important;

      #clickdimensionsForm {
        .responsiveRow {
          display: block;

          .responsiveCell.responsiveCellSize1 {
            min-width: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
