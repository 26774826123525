/*!
* WS Starter
* Designed by LRS Web Solutions (http://lrswebsolutions.com)
* Visit http://lrswebsolutions.com
*/

// Presets & Variables
@import 'presets/variables';
@import 'presets/mixins';
// Normalize
@import 'vendor/normalize';
// Additional Presets & Variables
@import 'presets/grid-settings';
@import 'presets/helper-classes';
// Layout
@import 'layout/header';
@import 'layout/content';
@import 'layout/footer';
// Vendor
@import 'vendor/responsive-videos';
@import 'vendor/modal';
@import 'vendor/forms';
// Components
@import 'components/general';
@import 'components/typography';
@import 'components/branding';
@import 'components/nav-primary';
@import 'components/nav-secondary';
@import 'components/lrs-corp-bar';
@import 'components/lrs-cookie-bar';
@import 'components/lrs-cookie-popup';
@import 'components/accessibility';
@import 'components/tables';
@import 'components/accordions';
@import 'components/tabs';
@import 'components/tablesaw';
@import 'components/forms';
@import 'components/mobile-menu';
@import 'components/homepage';
@import 'components/homepage-banner';
@import 'components/subpage';
@import 'components/breadcrumbs';
@import 'components/widgets';
@import 'components/subpage-healthcare';
@import 'components/subpage-manufacturing';
@import 'components/subpage-financial';
@import 'components/subpage-workplace';
@import 'components/subpage-modernization';
@import 'components/subpage-output-management';
@import 'components/landing-page';
@import 'components/blog';
@import 'components/learn-more';
@import 'components/blog-details';
@import 'components/subscribe';
@import 'components/events';
@import 'components/locations';
@import 'components/search';
@import 'components/sharing-buttons';
@import 'components/clickd';
@import 'components/request-demo';
@import 'components/print';
@import 'components/print-homepage';
@import 'components/print-footer';
@import 'components/print-sidebar';
@import 'components/print-quiz';
@import 'components/print-typography';
@import 'components/print-subpage';
@import 'components/support-map';
@import 'components/calculator';
@import 'components/featured-blog';
@import 'components/knowledge-base';
@import 'components/columns';
@import 'components/modal';
@import 'components/tooltips';
@import 'components/documentation';