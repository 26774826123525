.eventlist {
  h3 {
    margin: 36px 0 -5px 0;
  }

  .event {
    position: relative;
    padding: 20px 20px 0;
    border-bottom: 1px solid #ffffff;

    .day {
      position: absolute;
      top: 0;
      left: 0;
      display: table-cell;
      padding: 30px 15px;
      height: 100%;
      background: #757575;
      color: #ffffff;
      font-weight: 700;
      font-size: 15px;
    }

    .details {
      margin-left: 50px;
      border-bottom: 1px solid #ededed;

      h4 {
        font-weight: 400;
        font-size: 1.125rem;
      }

      .location {
        padding: 5px 0 10px;
      }
    }
  }
}

.event-details {
  font-family: Roboto,sans-serif;

  .event-dates {
    margin-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid #d9d9d9;
    color: inherit;
    font-weight: 400;
    font-style: normal;
    font-size: 14px !important;
    line-height: 1.4;

    text-rendering: optimizeLegibility;
  }

  h1,
  h2 {
    margin-top: 0;
    margin-bottom: 1.25rem;
    color: inherit;
    color: #0032a0;
    font-weight: 700;
    font-style: normal;
    font-size: 1.5rem;
    line-height: 1.4;

    text-rendering: optimizeLegibility;
  }

  p {
    margin-bottom: 16px;
    font-size: 16px;
    line-height: 32px;
  }

  label {
    display: block;
    margin: 0;
    color: #0a0a0a;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.8;
  }

  input,
  select {
    display: block;
    box-sizing: border-box;
    margin: 0 0 1rem;
    padding: 0.5rem;
    width: 100%;
    height: 2.4375rem;
    border: 1px solid #cacaca;
    border-radius: 0.3125rem;
    background-color: #fefefe;
    box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
    color: #0a0a0a;
    font-size: 1rem;
    transition: box-shadow 0.5s,border-color 0.25s ease-in-out;
  }

  input[type=submit] {
    display: inline-block;
    margin: 0 0 1rem 0;
    padding: 0.75em 2em;
    width: auto;
    border: 1px solid transparent;
    border-radius: 0.3125rem;
    background-color: #0032a0;
    color: #fefefe;
    vertical-align: middle;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 0.0625rem;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1;
    cursor: pointer;
    transition: background-color 0.25s ease-out,color 0.25s ease-out;

    -webkit-appearance: none;
  }

  input[type=checkbox],
  input[type=radio] {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;

    -webkit-appearance: checkbox;
  }

  ul li {
    font-size: 16px;
    line-height: 32px;
  }
}
