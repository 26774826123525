.blog-rotator {
  background: #ebebeb;
}

.blog-rotator > div {
  padding: 35px 25px 0px;
  max-width: 1300px;
  margin: 0 auto;

  @media screen and (min-width: 1010px) {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    padding: 0 0 0 20px;
  }

  > div:first-child {
    > div {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;

      @media screen and (min-width: 1010px) {
        max-width: none;
        margin: 0;
      }
    }

    @media screen and (min-width: 1010px) {
      max-width: 800px;
      margin: 65px 0 80px;
      width: calc(100% - 400px);
      position: relative;
      z-index: 4;
    }
  }

  .featured-image {
    max-width: 100%;
    border: 2px solid #fff;
    box-shadow: 0px 0 5px rgba(0, 0, 0, .15);

    @media screen and (min-width: 1010px) {
      max-width: 360px;
      max-height: 250px;
      margin-right: 40px;
      height: auto;
    }
  }

  h2 {
    font-size: 16px;
    color: #333333;
    text-transform: uppercase;
    font-family: 'Roboto', Arial, Helvetica, sans-serif;
    letter-spacing: .1px;
    margin: 27px 0;
    font-weight: bold;

    @media screen and (min-width: 1010px) {
      margin-top: 0px;
    }
  }

  .blog-post {
    width: 100% !important;

    @media screen and (min-width: 1010px) {
      //Override Cycle2 inline styles
      display: flex !important;
      justify-content: flex-start !important;
      align-items: flex-start;
    }
  }

  .post-details {
    h3 {
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 24px;
      color: #0032a0;
      line-height: 30px;
      font-weight: bold;
    }

    p {
      color: #000;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 18px;
      line-height: 30px;
    }

    a {
      display: inline-block;
      margin-top: 10px;
      padding: 15px 20px;
      font-size: 14px;
      font-weight: bold;
      letter-spacing: .1px;
      background-color: #0032a0;
      color: #fff;
      text-transform: uppercase;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      border-radius: 5px;
      text-decoration: none;
    }
  }

  .read-more {
    background: #e2e2e2;
    width: calc(100% + 45px);
    margin: 80px -20px 0;
    padding: 10px 20px 60px;

    @media screen and (min-width: 1010px) {
      min-width: 320px;
      max-width: 320px;
      margin: 0 0 0 80px;
      display: block;
      padding: 75px 40px 0;
      position: relative;
      z-index: 5;
    }

    h2 {
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    }

    ul {
      list-style: none;
      margin: 0 auto;
      padding: 0;
      max-width: 500px;

      li {
        margin-bottom: 20px;

        &:nth-child(-n+4) {
          display: none !important;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    a {
      text-decoration: none;
      color: #0032a0;
      font-family: 'Roboto', Arial, Helvetica, sans-serif;
      font-size: 14px;
      line-height: 18px;
      font-weight: bold;
    }
  }

  > div > div.blog-pager {
    display: flex;
    font-size: 25px;
    margin-top: 40px;
    color: #07a1d9;
    align-items: center;
    justify-content: center;

    @media screen and (min-width: 1010px) {
      margin-top: 60px;
    }

    .fa-arrow-circle-left {
      margin-right: 10px;
      cursor: pointer;
    }

    .fa-arrow-circle-right {
      margin-left: 10px;
      cursor: pointer;
    }

    .indicators {
      width: 100%;
      display: flex;
    }

    .indicator {
      width: 100%;
      background: #cccccc;
      height: 5px;
      border-radius: 5px;
      margin-right: 3px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }

      &.cycle-pager-active {
        background: #07a1d9;
      }
    }
  }
}
