.cookie-popup {
  display: none;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  &.active {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-top: 100px;

    @media only screen and (max-width: 486px) {
      padding-top: 25px;
    } 
  }

  .cookie-box {
    max-width: 800px;
    background: #fff;

    @media only screen and (max-width: 768px) {
      width: 90%;
    } 

    .title {
      background: #cddb28;
      padding: 30px 0 30px 80px;
      text-transform: uppercase;
      font-family: roboto-condensed, sans-serif;   
      font-size: 2rem;
      font-weight: 200;
      letter-spacing: 2px;
      position: relative;

      @media only screen and (max-width: 768px) {
        padding: 30px 70px 30px 50px;
        font-size: 1.2rem;
        
      } 

      @media only screen and (max-width: 486px) {
        padding: 30px 50px;
      } 

      .close-it {
        position: absolute;
        top: 30px;
        right: 40px;
        opacity: 0.5;
        cursor: pointer;
      }
    }

    .info {
      padding: 30px 80px 40px;
      font-family: Roboto,sans-serif;  

      @media only screen and (max-width: 768px) {
        padding: 30px 50px 40px;
      } 

      @media only screen and (max-width: 486px) {
        padding: 10px 30px 30px 30px;
      } 

      p {
        line-height: 2rem;
        margin-bottom: 40px;
        font-size: 1rem;

        @media only screen and (max-width: 486px) {
          font-size: 14px;
          line-height: 1.6rem;
          margin-bottom: 10px;
        } 
      }

      .info-btns {

        .btns {
          padding: 7px 25px;
          border-radius: 5px;
          text-transform: uppercase;
          font-weight: 700;
          text-decoration: none;
          font-size: 0.9375rem;
          font-family: roboto, sans-serif;
          margin-right: 5px;
          display: inline-block;

          &.btn-accept {
            background: #0032a0;
            color: #fff;
            border: 2px solid #0032a0;

            @media only screen and (max-width: 486px) {
              margin-bottom: 10px;
            } 
  
          }
    
          &.btn-outline {
            color: #0032a0;
            border: 2px solid #e6e6e6;        
            background: transparent;
          }
        }

      }
    }
  }
}