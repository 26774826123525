nav.nav-primary {
  margin-top: 0.875rem;
  border-top: 2px solid #e6e6e6;
  border-bottom: none;
  font-weight: 300;
  font-style: normal;
  font-family: roboto-condensed, sans-serif;

  @media only screen and (max-width: 1023px) {
    display: none;
  }

  .ul1 {
    margin-top: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: center;

    li {
      position: relative;
      display: inline-block;
      margin-right: 1.9375rem;
      margin-bottom: 0;

      a {
        display: block;
        padding: 0;
        color: #0032a0;
        text-decoration: none;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 700;
        font-style: normal;
        font-size: 0.9375rem;
        font-family: roboto-condensed, sans-serif;
        line-height: 3.125rem;
      }
    }

    li.has_children {
      &:nth-last-child(2),
      &:last-child {
        .ul2 {
          right: 0;
          left: auto;

          .ul3 {
            right: 100%;
            left: auto;
          }
        }
      }

      &:hover {
        .ul2 {
          display: block;
          padding: 10px 0;
        }
      }

      &:focus-within {
        .ul2 {
          display: block;
          padding: 10px 0;
        }
      }

      &:focus {
        .ul2 {
          display: block;
          padding: 10px 0;
        }
      }

      .a2 {
        &:hover {
          background: #404040;
        }
      }
    }
  }

  .ul2 {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 115;
    display: none;
    background: #595959;
    text-align: left;

    li#cirrato,
    li#drivve-products {
      .ul3 {
        display: none;
      }
    }

    li {
      position: relative;
      display: block;
      margin-right: 0;
      white-space: nowrap;

      a {
        display: block;
        padding: 0.5rem 1.5rem;
        color: #ffffff;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;
        font-style: normal;
        font-size: 0.9375rem;
        font-family: Roboto, sans-serif;
        line-height: 1.0625rem;
      }
    }

    li.has_children {
      &:hover {
        .ul3 {
          display: block;
        }
      }

      &:focus-within {
        .ul3 {
          display: block;
        }
      }

      &:focus {
        .ul2 {
          display: block;
          padding: 10px 0;
        }
      }
    }
  }

  .ul3 {
    position: absolute;
    top: 0;
    left: 100%;
    z-index: 115;
    display: none;
    padding: 12px 20px;
    background: #404040;

    li {
      position: relative;
      white-space: nowrap;

      a {
        display: block;
        padding: 0.5rem 1.5rem;
        color: #ffffff;
        text-transform: none;
        letter-spacing: 0;
        font-weight: 400;
        font-style: italic;
        font-size: 0.9375rem;
        font-family: Roboto, sans-serif;
        line-height: 1.0625rem;

        &:hover {
          background: #333333;
        }
      }
    }
  }
}
