.widgets {
  .widget {
    position: relative;
    margin: 0 0 2.1875rem 0;
    color: #414141;
    font-size: 0.85rem;

    &:after {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      border: 2px solid #0032a0;
      content: '';
    }

    .subscribe {
      padding-left: 20px;

      h2 {
        padding-left: 0;
      }

      label {
        display: block;
        margin: 0;
        color: #0a0a0a;
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 1.8;
      }

      input[type='text'] {
        display: block;
        box-sizing: border-box;
        margin: 0 0 1rem;
        padding: 0.5rem;
        width: 100%;
        height: 2.4375rem;
        border: 1px solid #cacaca;
        border-radius: 0.3125rem;
        background-color: #fefefe;
        box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
        color: #0a0a0a;
        font-size: 1rem;
        font-family: inherit;
        transition: box-shadow 0.5s,border-color 0.25s ease-in-out;
      }

      .button {
        display: inline-block;
        margin: 0 0 1rem 0;
        padding: 0.75em 2em;
        border: 1px solid transparent;
        border-radius: 0.3125rem;
        background-color: #0032a0;
        color: #fefefe;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        font-size: 0.75rem;
        font-family: Roboto,sans-serif;
        line-height: 1;
        cursor: pointer;
        transition: background-color 0.25s ease-out,color 0.25s ease-out;

        -webkit-appearance: none;
      }
    }

    h2 {
      margin: 10px 0 18px;
      padding-left: 20px;
      font-weight: 700;
      font-size: 18px;
    }

    ul {
      margin: 0.3125rem 0;
      padding: 0;
      list-style: none;

      li.post {
        clear: both;
        margin-bottom: 0.5rem;
        padding: 20px;
        padding-left: 20px !important;
        min-height: 100px;
        border-top: 1px solid #d9d9d9;

        img {
          float: left;
          padding-right: 0.625rem;
        }

        h3 {
          display: block !important;
          overflow: hidden;
          margin: 0;
          padding: 0;
          text-transform: none;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
        }

        a {
          color: #414141;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }
    }
  }
}
