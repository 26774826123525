.documentation {
  .container {
    background: #ffffff !important;
  }

  .content-wrapper {
    @media only screen and (min-width: 1024px) {
      display: flex;
      align-items: flex-start;
      align-content: flex-start;
      max-width: 1300px !important;
    }
  }

  .search-container {
    display: block;
    float: right;
    position: relative;
    padding: 0;
    width: 100%;
    padding-bottom: 38px;
    border-bottom: 1px solid #d9d9d9;
    margin-bottom: 30px;
    // border: 1px solid #d9d9d9;
    // border-radius: 5px;

    input {
      padding-left: 20px;
      padding-right: 60px;
      width: calc(100% - 80px);
      height: 50px;
      border: 0;
      background-color: transparent;
      border: 1px solid #d9d9d9;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;

      &::placeholder {
        color: $mineshaft;
      }

      &:focus {
        outline: 0;
        border: 1px solid $dovegray;
      }
    }

    .search,
    .cancel {
      position: absolute;
      width: 80px;
      height: 50px;
    }

    // .cancel {
    //   top: 0;
    //   right: 0;
    //   padding: 5px 0 0 9px;
    //   color: white;
    //   background: $dovegray;

    //   &:hover,
    //   &:focus {
    //     background: darken($dovegray, 10%);
    //   }
    // }

    .search {
      top: 0;
      right: 0;
      padding: 13px 0 0 31px;
      color: $mineshaft;
      font-size: 18px;
      background: #f2f2f2;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid #d9d9d9;
      border-left: none;

      span.fa {
        color: #0032a0;
      }

      &:hover,
      &:focus {
        background: $mercury;
      }
    }
  }

  section.main {
    flex: 0 0 77%;
    margin: 0 0 100px;
    padding-right: 0px;
    padding-left: 0px;
    max-width: 100%;
    order: 2;
    @include large-up {
      padding-left: 40px;
      padding-right: 0;
      max-width: 77%;
    }

    h2 {
      font-size: 24px;
      font-weight: 400;
    }

    h3 {
      color: $mineshaft;
      font-size: 18px;
      margin-bottom: 5px;
      margin-top: 25px;
    }

    p {
      margin-top: 0;
    }
  }

  .sidebar {
    @include large-up {
      // position: -webkit-sticky;
      // position: sticky;
      // top: 0;
      // height: 100vh;
      // overflow-y: scroll;
    }

    &.deco {
      width: 100%;
      padding: 0;
      background-color: transparent;
      // padding: 20px;
      // position: sticky;
      margin-bottom: 30px;
      border: none;
      border-radius: 3px;
      font-size: 16px;
      line-height: 24px;
      font-size: 1rem;
      line-height: 1.5rem;
      letter-spacing: normal;
    }

    h3 {
      margin: 0;
    }

    .nav-list {
      list-style: none;
      margin: -10px 0 0 0;
      padding-left: 0;

      li a {
        position: relative;
        color: #595959;
        font-style: italic;
        font-weight: 600;;
        padding-left: 35px;
        padding-right: 20px;

        &:hover,
        &:focus {
          background-color: #e2e2e2;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color: #d9d9d9;
          }
        }

        span {
          color: #06a7e2;
          margin-right: 5px;
        }
      }
    }

    .divider {
      height: 1px;
      background: $mercury;
      margin: 0 0 5px 0;
    }

    a {
      display: block;

      &.list-group-item-success,
      &.list-group-item-info {
        background: $alabaster;
      }

      &:not(.list-group-item) {
        padding: 0 5px;
        font-weight: 400;

        &:hover,
        &:focus {
          background: $alabaster;
          text-decoration: none;
          border: 0;
        }
      }
    }
  }

  table {
    margin-top: 10px;

    thead {
      th {
        padding: 20px 30px;
        background-color: $kleinblue;
        border-color: #335bb3;
        border-bottom: none;

        &:first-child {
          border-top-left-radius: 20px;
          border-color: #335bb3;
        }

        &:last-child {
          border-top-right-radius: 20px;
        }

      }
    }

    tbody {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: 0px 0px 5px rgba(0,0,0,0.2);
      tr {
        &:first-child {
          td {
            border-top: 10px solid #ffffff;
          }
        }

        &:last-child {
          td {
            border-bottom: 10px solid #ffffff;

            &:first-child {
              border-bottom-left-radius: 20px;
            }

            &:last-child {
              border-bottom-right-radius: 20px;
            }
          }
        }

        td {
          padding: 15px 30px;

          &:first-child {
            border-left: 10px solid #ffffff;
            padding-left: 20px;
          }

          &:last-child {
            border-right: 10px solid #ffffff;
          }
        }
      }
    }
  }
}

.subpage.documentation .sidebar {
  nav.sub-navigation {
    position: relative;
    margin: 0 0 30px 0;
    border: 1px solid #e2e2e2;
    line-height: 1.6rem;

    &.default {
      .view-more {
        position: absolute;
        top: 0;
        right: 0;
        width: 50px;
        height: 52px;
        color: #9c9c9c;
        text-align: center;
        cursor: pointer;

        span {
          position: relative;
          top: 13px;
        }

        &:hover {
          background: #ebebeb;
        }
      }

      .ul2 {
        padding: 0;
        margin-left: 0;

        li {
          position: relative;
          border: none;
          list-style: none;

          &:after {
            display: none;
          }

          a {
            position: relative;
            display: block;
            padding: 0.5rem 0.9375rem 0.5rem 2.625rem;
            border-left: 5px solid rgba(146, 146, 146, 0) ;
            font-weight: 600;
            color: #595959;
            text-decoration: none;
            font-size: 14px;
            font-style: italic;

            &:hover {
              border-left: 5px solid rgba(146, 146, 146, 0.1) ;
              color: #333333;
            }

            &:before {
              position: absolute;
              left: 25px;
              content: '>';
              color: #06a7e2;
            }
          }
        }
      }
    }

    .title {
      display: none;
    }

    .ul1 {
      position: relative;
      margin: 0;
      padding: 0;
      background: #f5f5f5;
      list-style: none;

      li {
        position: relative;
        border-bottom: 1px solid #e2e2e2;
        font-size: 0.875rem;

        a {
          position: relative;
          display: block;
          padding: 1.125rem 2.675rem 1rem 1.25rem;
          border-left: 5px solid rgba(146, 146, 146, 0.1);
          background-size: 3.1875rem;
          color: #333333;
          text-decoration: none;
          line-height: 1.125rem;
          font-weight: 600;
          font-size: 16px;

          &:hover,
          &:focus {
            background-color: #ebebeb;
            border-left: 5px solid rgba(146, 146, 146, 0.1);

          }
        }

        .ul2 {
          display: none;
          margin-bottom: 20px;

          &:before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 5px;
            height: 100%;
            background-color:#ebebeb;
          }
        }

        &.active {
          .ul2 {
            display: block;
          }
        }
      }
    }
  }
}