.breadcrumb-container {
  padding-right: 0.9375rem;
  padding-left: 0.9375rem;

  .breadcrumb {
    margin-right: auto;
    margin-bottom: 2.8125rem;
    margin-left: auto;
    padding: 1.0625rem 0 1.25rem 0;
    max-width: 75rem;
    border-bottom: solid 2px #e6e6e6;
    color: #a4a4a4;
    list-style: none;
    font-size: 0.8125rem;
    font-family: Roboto, sans-serif;
    line-height: 0.875rem;

    @media only screen and (max-width: 1023px) {
      display: none;
    }

    li {
      display: inline-block;

      a {
        color: #0032a0;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
        }
      }

      &:after {
        padding: 0 5px;
        content: '>';
      }

      &:last-child {
        color: #6a6a6a;
        font-weight: 500;

        &:after {
          display: none;
        }
      }
    }
  }
}
