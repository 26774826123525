.social {
  display: block;
  margin-top: 10px;
  padding: 0;

@include medium-up {
    display: flex;
    margin-top: 0;
    padding: 0.75rem 0;

    align-items: center;
    justify-content: space-between;
  }

  &.bottom {
    margin-top: 0;
  }

  .label {
    position: relative;
    bottom: -2px;
    display: inline-block;
    margin-right: rem-calc(10px);
    text-transform: uppercase;
    letter-spacing: 0.063em;
    font-weight: 500;

@include fontsize(14 14);
  }

  ul.buttons {
    display: inline-block;
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: 0 10px 0 0;
      padding: 0;

      &:before {
        display: none;
      }

      &:last-child {
        margin-right: 0;
      }
    }

    .fa-stack {
      width: rem-calc(29px);
      height: rem-calc(29px);
      line-height: rem-calc(29px);
    }

    .fa-stack-2x {
      font-size: rem-calc(29px);
    }

    .fa-stack-1x {
      font-size: rem-calc(15px);
    }

    .icon {
      color: white;
    }

    .facebook .fa-stack-2x {
      color: #3b5998;

      &:visited {
        color: #3b5998;
      }
    }

    .twitter .fa-stack-2x {
      color: #1da1f2;

      &:visited {
        color: #1da1f2;
      }
    }

    .linkedin .fa-stack-2x {
      color: #0077b5;

      &:visited {
        color: #0077b5;
      }
    }

    .gplus .fa-stack-2x {
      color: #db4437;

      &:visited {
        color: #db4437;
      }
    }

    .xing .fa-stack-2x {
      color: #1a7576;

      &:visited {
        color: #1a7576;
      }
    }

    .facebook,
    .twitter,
    .linkedin,
    .gplus,
    .xing {
      &:hover .fa-stack-2x,
      &:focus .fa-stack-2x {
        color: gray;
      }
    }
  }
}
