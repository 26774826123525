.faq-category-name {
  margin-bottom: -15px;
}

.accordions {
  margin-bottom: 30px;

  h3.accordion-title {
    position: relative;
    margin: 30px 0 0 0;
    padding: 0;
    // min-height: 35px;
    border-bottom: 2px solid #e6e6e6;
    cursor: pointer;

    &:hover,
    &:focus {
      border-color: #0032a0;
      color: #0032a0;
      cursor: pointer;

      span {
        &:after {
          color: #0032a0;
          opacity: 1;
        }
      }
    }

    &.is-active {
      border-bottom: 2px solid #17468f;

      button {
        color: #0032a0;

        &:hover,
        &:focus {
          color: #0032a0;
        }
      }

      span {
        &:after {
          color: #333333;
          content: '\f106';
          font-size: 16px;
          font-family: FontAwesome;
        }
      }
    }

    button {
      // position: absolute;
      // top: 0;
      display: block;
      // margin-left: -10px;
      padding: 5px 45px 5px 0;
      width: 100%;
      height: 100%;
      border: 0;
      background: transparent;
      color: #333333;
      text-align: left;
      // text-indent: 10px;
      font-size: 16px;
      cursor: pointer;

      &:hover,
      &:focus {
        color: #0032a0;
      }

      &:focus {
        outline: 0;
      }
    }

    span {
      position: absolute;
      top: 6px;
      right: 0;
      width: 45px;
      height: 20px;
      text-indent: 0;

      &:last-of-type {
        // height: 43px;
      }

      &:after {
        position: absolute;
        top: 0;
        // left: 0;
        right: 0;
        // margin: 0 auto;
        width: 15px;
        height: 17px;
        color: #333333;
        content: '\f107';
        text-align: center;
        text-decoration: inherit;
        font-weight: normal;
        font-style: normal;
        font-size: 16px;
        font-family: FontAwesome;
        transform: translateY(-8px);
      }
    }
  }

  .accordion-title + [aria-hidden] {
    display: none;
  }

  .accordion-title + [aria-hidden='false'] {
    display: block;
  }

  .accordion-content {
    margin: 2px 0 5px;
    padding: 30px 15px 35px;
    background: #f5f5f5;

    p {
      margin: 0 0 10px;
      color: #333333;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.5rem;
    }
  }
}

//Other Accordions
.accordion {
  margin-top: rem-calc(25px);

  h3 {
    margin: 0;
    padding-bottom: rem-calc(5px);
    padding-left: rem-calc(16px);
    border-left: 3px solid #bfbfbf;
    font-size: rem-calc(18px);
    line-height: rem-calc(24px);
  }

  .blurb {
    margin-top: 0;
    margin-bottom: 0;
    padding-bottom: rem-calc(16px);
    padding-left: rem-calc(16px);
    border-left: 3px solid #bfbfbf;
    font-weight: 300;
    font-size: rem-calc(15px);
    line-height: rem-calc(24px);
  }

  .content {
    display: none;
    padding-left: rem-calc(16px);
    border-left: 3px solid #bfbfbf;
    font-weight: 300;
    font-size: rem-calc(15px);
    line-height: rem-calc(24px);

    p {
      margin-top: 0;
      font-weight: 300;
      font-size: rem-calc(15px);
      line-height: rem-calc(24px);
      // &:last-of-type {
      //   margin-bottom: 0;
      // }
    }
  }

  .show-hide {
    position: relative;
    display: inline-block;
    display: inline-block;
    margin-bottom: rem-calc(40px);
    margin-left: rem-calc(16px);
    padding-top: rem-calc(10px);
    border-top: 1px solid #bfbfbf;
    color: #707070;
    text-transform: uppercase;
    font-weight: 600;
    font-size: rem-calc(12px);
    line-height: rem-calc(12px);
    cursor: pointer;

    &:before {
      position: absolute;
      top: -1px;
      left: rem-calc(-16px);
      width: 3px;
      height: 100%;
      background: #bfbfbf;
      content: '';
    }

    &:after {
      position: relative;
      float: right;
      margin-left: rem-calc(5px);
      color: #bfbfbf;
      content: '\f0d7';
      text-decoration: inherit;
      font-weight: normal;
      font-style: normal;
      font-family: FontAwesome;
    }

    &.active:after {
      content: '\f0d8';
    }
  }
}
