.tabs {
  margin: 0 0 20px;

  ul.tab-list {
    display: none;
    margin: 0 !important;
    border-top: none;
    list-style: none;

    &[role='tablist'] {
      display: block;
      padding: 0;

      @media only screen and (max-width: 1023px) {
        // display: none;
      }

      a {
        color: #091b36;
      }
    }

    li {
      display: inline-block;
      margin: 0 0 0 3px !important;
      padding: 0;
      background: #e6e6e6;
      white-space: nowrap;

      @media only screen and (max-width: 1023px) {
        margin: 0 !important;
        width: 100%;
        white-space: normal;
      }

      &:hover,
      &:focus {
        background: #ebebeb;
        color: #002473;
      }

      &:before {
        display: none;
      }

      &:first-child {
        margin: 0 !important;
      }

      &:last-child {
        border-bottom: inherit;
      }

      a {
        display: block;
        padding: 6px 12px;
        color: #333333;
        font-weight: 700;
        font-size: 0.875rem;

        &:hover,
        &:focus {
          border-bottom: none;
          text-decoration: underline;
        }

        &[aria-selected] {
          background: #0032a0;
          color: white;
        }
      }
    }

    li.active {
      background: #0032a0;

      a {
        color: #ffffff;

        &:hover,
        &:focus {
          text-decoration: none;
        }
      }
    }
  }

  [role='tabpanel'] {
    padding: 30px 15px 35px;
    background: #f5f5f5;

    p {
      margin-top: 0;
      margin-bottom: 1rem;
      color: #333333;
      font-size: 16px;
      line-height: 1.5rem;
    }

    &[aria-hidden='true'] {
      display: none;
    }

    .tab-title {
      display: none;
    }
  }
}

.mobile-tab {
  display: none;

  @media only screen and (max-width: 1023px) {
    position: relative;
    display: block;
    margin: 30px 0 2px 0;
    padding: 8px 0;
    border-bottom: 2px solid #e6e6e6;
    color: #333333 !important;
    font-size: 16px;
    cursor: pointer;

    &:hover {
      border-color: #0032a0;
      color: #0032a0 !important;
      text-decoration: none !important;
      cursor: pointer;

      &:after {
        color: #0032a0;
        opacity: 1;
      }
    }

    &:focus,
    &[aria-selected='true'] {
      outline: none;
      border-color: #0032a0 !important;
      border-bottom: 2px solid #0032a0;
      color: #0032a0 !important;
    }

    &:after {
      position: relative;
      top: -5px;
      float: right;
      margin-right: 5px;
      width: 15px;
      height: 17px;
      color: #333333;
      content: '\f107';
      text-align: center;
      text-decoration: inherit;
      font-style: normal;
      font-size: 16px;
      font-family: FontAwesome;
    }
  }
}

a.mobile-tab[aria-selected='true']:after {
  position: relative;
  top: -5px;
  float: right;
  margin-right: 5px;
  width: 15px;
  height: 17px;
  color: #333333;
  content: '\f106';
  text-align: center;
  text-decoration: inherit;
  font-style: normal;
  font-size: 16px;
  font-family: FontAwesome;
}
