.cookiebar-container {
  // background-color: #cddb28;
  padding: 25px 0;
  font-family: Roboto,sans-serif;  
  display: none;
  background: #cddb28 url('/Themes/EOM/assets/dist/images/lrsarrows.png') no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1380px) {
    background: #cddb28 url('/Themes/EOM/assets/dist/images/lrsarrows-80.png') no-repeat;
    background-size: contain;
  }   

  .inner {
    max-width: 75rem;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media only screen and (max-width: 1280px) {
      padding: 0 60px;
    }      

    @media only screen and (max-width: 1280px) {
      display: block;
      max-width: 100%;
    }

    p {
      margin: 0;
      line-height: 2rem;    
      width: 55%;  

      @media only screen and (max-width: 1280px) {
        width: 100%;
        margin-bottom: 20px;
      }   

      @media only screen and (max-width: 486px) {
        font-size: 14px;
        line-height: 1.6rem;
        margin-bottom: 10px;
      } 
    }

    a {
      color: #0032a0;
      text-decoration: none;
      font-weight: 700;
    }

    .btn-outline {
      border: 2px solid #acb647;
      padding: 7px 25px;
      background: #cddb28;
      border-radius: 5px;
      text-transform: uppercase;
      font-weight: 700;
      text-decoration: none;
      color: #000;
      font-size: 0.9375rem;
      font-family: Roboto,sans-serif;  
      display: inline-block;

      @media only screen and (max-width: 586px) {
        margin-bottom: 10px;
      } 
      
      &:hover {
        background: rgba(172,182,71,0.4);
      }

      &:last-of-type {
        margin-left: 5px;

        @media only screen and (max-width: 486px) {
          margin-bottom: 0;
          margin-left: 0;
        }
      }
    }
  }
}