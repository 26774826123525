body.print-sub {
  min-height: 100%;
  background-color: #0b3997;
  background-image: url(/themes/eom/assets/dist/images/print/bkgd.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  // Layout
  .text-container {
    display: flex;
    margin: 0 auto;
    padding-bottom: 75px;
    max-width: 1280px;
    height: inherit;

    flex-wrap: wrap;
  }

  .content-main {
    flex: 3;
    max-width: 1280px;

    p {
      font: 400 18px/30px Roboto,sans-serif;
    }

    ul {
      margin-bottom: 1rem;
      margin-left: 1.25rem;
      list-style-position: outside;
      list-style-type: disc;
      line-height: 2rem;

      li {
        margin-bottom: 0.5rem;
        font: 400 18px/30px Roboto,sans-serif;
      }
    }
  }

  .page-title {
    position: relative;

//   display: inline-flex;
    overflow: hidden;
    margin: 0 auto;
    max-width: 1280px;
    max-height: 100%;
    width: 100%;
    height: 225px;
    background: none;

    h1 {
      color: #ffffff;
      letter-spacing: normal;
      font-size: 40px;
      font-family: 'Roboto Condensed',sans-serif;
      line-height: 48px;

      @media (max-width: 1024px) {
        font-size: 36px;
        line-height: 42px;
      }

      @media (max-width: 500px) {
        font-size: 24px;
        line-height: 36px;
      }
    }
  }

  .page-title-text {
    position: relative;
    float: left;
    width: 65%;
    height: 100%;
    animation: fadeIn 2s forwards;
  }

  .sp-icn-container {
    float: right;
    padding: 25px 0 0 0;
    width: 35%;
    height: 100%;
    text-align: right;
  }

  .content {
    padding: 100px 0 0 0;
    min-height: 500px;
    width: 100%;
    background-color: white;
    animation: moveUpContent 0.9s forwards, fadeIn 0.9s forwards;
  }

  // Call to action styles
  .call-to-action {
    margin: 0 auto;
    padding: 80px 0 0;
    width: 100%;
    height: auto;
    background: linear-gradient(to bottom right, rgba(220, 235, 0, 0.95), rgba(206, 219, 0, 0.95)), url('/themes/eom/assets/dist/images/print/cta-unauthorized-access-bkgd.jpg');
    background-size: cover;
    background-repeat: no-repeat;

    .cta-button {
      margin-bottom: 40px;
      padding: 0 50px 80px;
      width: 100%;
      height: auto;
      border-bottom: 1px solid #9ca601;
      text-align: center;

      &:last-child {
        margin-bottom: 0;
        border-bottom: none;
      }

       > img {
        margin: 0 auto 20px;
        width: 120px;
        height: 120px;
        opacity: 0.35;
      }

      p {
        font-size: 24px;
      }

      a {
        font-family: sans-serif;
      }

      a:not(.dnnPrimaryAction) {
        color: #333333;
        font-size: 16px;

        &:hover,
        &:visited {
          padding-bottom: 3px;
          border-bottom: 1px solid;
          color: #000000;
          transition: 0.5s;
        }

        &:after {
          margin-left: 10px;
          color: #9ea806;
          content: '\f061';
          font-family: 'FontAwesome';
        }
      }

      a.dnnPrimaryAction {
        display: block;
      }

      @media only screen and (min-width: 768px) {
        &:last-child {
          border-bottom: none;
        }
      }

      @media only screen and (min-width: 1025px) {
        padding: 0;
        width: 20%;
        border-bottom: none;

        p {
          margin-right: auto;
          margin-left: auto;
          max-width: 400px;
        }
      }
    }

    @media only screen and (min-width: 1025px) {
      .text-container {
        padding-bottom: 50px;
      }
    }
  }

  // Styles for resolutions 1366px to 1280px
  @media only screen and (max-width: 1366px) and (min-width: 1280px) {
    .page-title {
      padding: 0 50px;
    }

    .text-container {
      padding: 0 100px 150px 50px;
      max-width: 100%;
    }
  }

  // Styles for resolutions 1366px and below
  @media only screen and (max-width: 1280px) {
    .content {
      padding: 75px 0 0 0;
    }

    .page-title {
      padding: 0 50px;
    }

    .text-container {
      padding: 0 50px 150px;
      width: 100%;
    }
  }

  // Styles for resolutions 1024px and below
  @media only screen and (max-width: 1024px) {
    .content {
      animation: none;
    }

    .sp-icn-container {
      display: none;
    }

    .page-title-text {
      padding: 0 50px;
      width: 100%;
      text-align: center;
    }

    .text-container {
      flex-direction: column;
      padding-bottom: 0;
      width: 100%;
    }

    .content-main {
      margin-bottom: 100px;
      padding-right: 0;
    }
  }

  // Styles for resolutions 500px and below
  @media only screen and (max-width: 500px) {
    .page-title {
      padding: 0 30px;
      height: 150px;
    }

    .page-title-text {
      padding: 0;
      width: 100%;
      text-align: center;
    }

    .text-container {
      padding: 0 50px;
      width: 100%;
    }
  }

  // Animations
  @keyframes moveUpContent {
    from {
      margin-top: 500px;
    }

    to {
      margin-top: 0;
    }
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  // sidebar
  .sidebar {
    position: relative;
    padding-left: 0;
    border-left: none;

    * {
      line-height: 1.6rem;
    }

    h4 {
      margin-bottom: rem-calc(15px);
      color: palette(gray, 9);
      font-weight: bold;
    }

    .more {
      margin-top: rem-calc(15px);
      font-size: 0.9rem;

      a {
        color: #737373;
        font-weight: bold;
      }
    }

    ul {
      margin: rem-calc(5px 0);
      padding: 0;

      li {
        list-style: none;

        //font-size: 1rem;
        h5 {
          margin: 0;
          letter-spacing: normal;
          font-weight: 300;

          // @include font-size(15, 21);
        }

        a {
          color: #414141;
        }
      }
    }

    .widgets {
      position: relative;
      margin: rem-calc(0 0 35px 0);
      padding: rem-calc(0 20px);
      color: #414141;
      font-size: 0.85rem;

      img {
        float: left;
        width: 40%;
      }

      .post {
        // clear: both;
        // margin-bottom: rem-calc(8px);
        // padding: rem-calc(5px 0);

        img {
          padding-top: rem-calc(4px);
          padding-right: rem-calc(6px);

          @media only screen and (max-width: 1024px) {
            width: auto;
          }
        }

        h5 {
          display: block;
          margin-top: 0;

//width: 58%;
          text-transform: none;
          font-weight: 400;
        }
      }

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: 5px;
        height: 100%;
        background: #0032a0;
        content: '';
      }
    }

    .recent-post {
      &::before {
        background: #0032a0;
      }
    }

    .learn-more {
      &::before {
        //background: #6949b5;
        //background: #575759; //changed to gray
        background: #dadada; //changed to lighter gray
      }

      ul {
        margin-left: 0;
        padding: 0;

        li {
          &.doc {
            position: relative;
            border-bottom: 1px dashed #9c9c9c;

            // @include font-size(14, 20);

            a,
            a:visited {
              color: #0032a0;
            }
          }
        }
      }
    }

    .recent-testimonials {
      // font-size: .9em;

      &::before {
        //background: #cedb08;
        background: #06a7e2;
      }

      blockquote {
        margin: rem-calc(0 0 10px 0);
        padding: 0;
        border: none;
        font-weight: 400;
        font-style: normal;
        font-size: rem-calc(15px);

        cite {
          color: #737373;
          font-style: italic;

          &::before {
            content: '';
          }
        }
      }
    }

    .recent-events {
      .event-title {
        font-style: italic;

        a {
          color: #414141;
        }
      }

      &::before {
        //background: #06a7e2;
        background: #8fc50d;
      }
    }
  }
}
