// .modal {
//   z-index: 3000;
// }

.modal-overlay {
  // z-index: 2000;
  // z-index: 1071;
}

body.modal-open {
  // position: absolute;
  // width: 100%;
  overflow: hidden;
  // z-index: 1000;
}

.modal-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transform: scale(1.1);
  transition: visibility 0s linear 0.25s, opacity 0.25s 0s;
  z-index: 1071;

  &.show-modal {
    opacity: 1;
    visibility: visible;
    transform: scale(1.0);
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
  }
}

.modal {
  display: none;
  background-color: #fff;
  text-align: center;
  width: 98vw;
  height: auto;
  min-height: calc(90vh - 40px);
  max-height: calc(90vh - 40px);
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  visibility: hidden;
  overflow: visible;
  z-index: 1072;

  @media only screen and (min-width: $medium) {
    width: 96vw;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow-y: visible;
  }

  @media only screen and (min-width: $large) {
    width: 60vw;
    max-width: 500px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;

    &.wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  @media only screen and (min-width: $xlarge) {
    width: 50vw;
    max-width: 640px;
    min-height: auto;
    height: auto;
    max-height: 90vh;
    overflow: visible;

    &.wide {
      width: 80vw;
      max-width: 80vw;
    }
  }

  &.show-modal {
    display: block;
    opacity: 1;
    visibility: visible;
    transition: visibility 0s linear 0s, opacity 0.25s 0s;
  }

  .close-modal {
    position: absolute;
    top: 0.5rem;
    right: 1.5rem;
    width: 1.5rem;
    font-size: 1rem;
    line-height: 1.5rem;
    text-align: center;
    cursor: pointer;
    border-radius: 0.25rem;
    background-color: lightgray;

    &:hover {
      background-color: darkgray;
    }
  }

  .modal-content {
    padding: 31px 20px 20px;
    height: 100%;
    overflow-y: auto;
    max-height: inherit;
  }
}

.modal-inline-parent {
  position: relative !important;
  z-index: auto !important;
}