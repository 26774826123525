body.print-home {
  min-height: 100%;
  background-color: #0b3997;
  background-image: url(/themes/eom/assets/dist/images/print/bkgd.jpg);
  background-attachment: fixed;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  // Layout
  .hp-container {
    position: relative;
    margin: 0 auto;

// left: 0;
    // right: 0;
    padding: 50px 0;
    max-width: 100%;

// max-width: 1280px;
    height: calc(100vh - 184px);
  }

  .printing-process {
    position: relative;

// height: calc(100vh - 184px);
    // margin: 100px 0 0 0;
    margin: 0 auto;
    max-width: 1280px;
    width: 100%;

// height: 1000px;
    height: 100%;

// background-image: url('/themes/eom/assets/dist/images/print/printer-bkgd.svg');
    background-image: url('/themes/eom/assets/dist/images/print/printer-bkgd-new.svg');
    background-position: top center;

// background-size: 1120px;
    background-size: contain;
    background-repeat: no-repeat;
    list-style: none;

    li {
      display: block;
    }
  }

  .unauthorized-access-btn {
    position: absolute;
    top: 0vh;
    right: 18vw;
    left: 40vw;
    left: auto;
    width: 360px;

// top: -20px;
    // left: 600px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.5s;
  }

  .release-btn {
    position: absolute;
    top: 13vh;
    left: 0vw;
    width: 320px;

// top: 180px;
    // left: 0;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.3s;
  }

  .watermarks-btn {
    position: absolute;
    top: auto;
    bottom: 0vh;
    left: 1vw;
    width: 360px;

// top: 565px;
    // left: 0;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.1s;
  }

  .audit-btn {
    position: absolute;
    top: 30vh;
    left: 25vw;
    width: 290px;

// top: 375px;
    // left: 368px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.2s;
  }

  .protect-print-data-btn {
    position: absolute;
    top: 35vh;
    right: 0vw;
    left: auto;
    width: 315px;

// top: 490px;
    // left: 875px;
    opacity: 0;
    animation: fadeIn 0.5s forwards;
    animation-delay: 0.4s;
  }

  .protect-printers-btn {
    position: absolute;
    top: auto;
    right: 16vw;
    bottom: 0vh;
    left: auto;
    width: 340px;

// top: 705px;
    // left: 620px;
    opacity: 0;
    animation: fadeIn 0.75s forwards;
    animation-delay: 0.6s;
  }

  // Homepage Button Styles
  .hp-btn {
    display: inline-flex;
    padding: 5px;
    width: 100%;
    height: 110px;
    border-radius: 100px;
    background-color: #00a3d2;
    box-shadow: 1px 1px 5px #001c59;
    transition: 0.75s;
  }

  .hp-btn * {
    transition: 0.75s;
  }

  .hp-btn .font-awesome {
    color: #07a1d9;
    font-size: 18px;
  }

  .icn-container {
    min-width: 100px;
    max-width: 100px;
    border-radius: 100px;
    background-color: #0587bf;
    background-image: url('/themes/eom/assets/dist/images/print/hp-icn-exclamation-point.svg');
  }

  .hp-btn-text {
    position: absolute;
    right: 0;
    width: calc(100% - 120px);
    text-align: left;

    &:after {
      display: none;
    }
  }

  .arrow-container {
    padding: 40px 40px 0 0;
    width: 60px;
    text-align: right;
    opacity: 0;
  }

  .arrow {
    display: none;
  }
}

// Hover styles for desktop
@media only screen and (min-width: 1024px) {
  .hp-btn:hover {
    border-radius: 100px;
    background-color: #243941;
    box-shadow: 0 0 0 2px rgba(0, 25, 70, 0.2);
    transform: scale(1.25, 1.25);
  }

  .hp-btn:hover .icn-container {
    background-color: #162227;
    transform: rotate(360deg);
  }

  .hp-btn:hover .arrow-container {
    padding: 40px 20px 0 20px;
    opacity: 1;
    transition: 1.5s;
  }
}

// Scale down for resolutions 1024px–1280px
@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .hp-container {
    // width: 1000px;
    height: calc(100vh - 184px);
  }

  .printing-process {
    height: calc(100vh - 184px);
  }

  .unauthorized-access-btn,
  .release-btn,
  .watermarks-btn,
  .audit-btn,
  .protect-print-data-btn,
  .protect-printers-btn {
    transform: scale(0.85, 0.85);
  }
}

// Styles for resolutions 1024px and below
@media only screen and (max-width: 1024px) {
  body.print-home {
    * {
      transition: 0s;
    }

    header {
      border-bottom: none;
    }

    .hp-container {
      position: relative;
      padding: 0;
      width: 100%;
      height: auto;
    }

    .printing-process {
      display: flex;
      flex-direction: column;
      margin: 0;
      padding: 0;
      height: 100%;
      background-image: none;
    }

    .unauthorized-access-btn,
    .release-btn,
    .watermarks-btn,
    .audit-btn,
    .protect-print-data-btn,
    .protect-printers-btn {
      position: relative;
      top: auto;
      right: auto;
      bottom: auto;
      left: auto;
      padding: 0;
      width: 100%;
      height: 250px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 1;
      animation: none;

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
      }
    }

    .unauthorized-access-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-unauthorized-access-tablet.svg');

      // order: 1;
      &:before {
        background-color: rgba(10, 105, 180, 0.8);
      }
    }

    .release-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-release-tablet.svg');

      // order: 2;
      &:before {
        background-color: rgba(0, 50, 160, 0.8);
      }
    }

    .watermarks-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-watermarks-tablet.svg');

      // order: 3;
      &:before {
        background-color: rgba(10, 105, 180, 0.8);
      }
    }

    .audit-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-audit-tablet.svg');

      // order: 4;
      &:before {
        background-color: rgba(0, 50, 160, 0.8);
      }
    }

    .protect-print-data-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-protect-print-data-tablet.svg');

      // order: 5;
      &:before {
        background-color: rgba(10, 105, 180, 0.8);
      }
    }

    .protect-printers-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-protect-printers-tablet.svg');

      // order: 6;
      &:before {
        background-color: rgba(0, 50, 160, 0.8);
      }
    }

    .bkgd-light {
      width: 100%;
      height: 100%;
      background-color: rgba(10, 105, 180, 0.8);
    }

    .bkgd-dark {
      width: 100%;
      height: 100%;
      background-color: rgba(0, 50, 160, 0.8);
    }

    .hp-btn {
      position: absolute;
      top: 0;
      left: 0;
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 100%;
      width: 100%;
      height: 100%;
      border-radius: 0;
      background-color: transparent;
      box-shadow: none;
    }

    .hp-btn .fa {
      margin: 0;
      color: #cbdb2f;
      font-size: 24px;
    }

    .icn-container {
      display: none;
    }

    .hp-btn-text {
      position: relative;
      margin: 0 auto 0 auto;
      padding: 0;
      min-width: 260px;
      max-width: 320px;
      width: 100%;
      text-align: center;
    }

    .arrow {
      display: block;
    }

    .fa {
      display: block;
      clear: both;
      margin: 20px auto 0 auto;
      color: #cedb00;
    }

    .watermarks-btn .hp-btn-text {
      max-width: 340px;
    }

    .arrow-container {
      margin: 20px 0 0 0;
      padding: 0;
      width: 100%;
      text-align: center;
      opacity: 1;
    }
  }
}

// Styles for resolutions 500px and below
@media only screen and (max-width: 500px) {
  body.print-home {
    .hp-btn-text {
      padding: 0;
      max-width: 265px;
      text-align: center;
    }

    .watermarks-btn .hp-btn-text {
      max-width: 265px;
      // margin-top: 60px;
    }

    .unauthorized-access-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-unauthorized-access-mobile.svg');
    }

    .release-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-release-mobile.svg');
    }

    .watermarks-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-watermarks-mobile.svg');
    }

    .audit-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-audit-mobile.svg');
    }

    .protect-print-data-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-protect-print-data-mobile.svg');
    }

    .protect-printers-btn {
      background-image: url('/themes/eom/assets/dist/images/print/hp-bkgd-protect-printers-mobile.svg');
    }
  }
}

// Animation
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

// Components
// Vendor

