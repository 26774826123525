header.primary {
  position: relative;
  margin: 0;
  padding: 2rem 0 1.75rem 1.75rem;
  max-width: 100%;
  width: 100%;

  @media only screen and (max-width: 1023px) {
    z-index: 2;
    padding: 3rem 0 1.75rem 0.5rem;
    background: white;
  }

  .contents {
    position: relative;
    display: flex;
    margin: 0 auto;
    max-width: 75rem;

    .logo {
      position: relative;
      display: inline-block;
      margin: 0;
      padding: 0;
      width: 206px;
      height: 72px;

      @media only screen and (max-width: 1023px) {
        width: 183px;
        height: 64px;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        background-image: url('/Themes/EOM/assets/dist/images/lrs-output-management-logo.svg');
        background-size: cover;
        text-indent: -10000px;
      }
    }
  }

  .nav-header {
    position: absolute;
    top: 1.25rem;
    right: 0;

    ul.menu {
      display: flex;
      margin: 0;
      width: 100%;
      list-style-type: none;

      align-items: center;
      flex-wrap: nowrap;

      @media only screen and (max-width: 1023px) {
        display: none;
      }

      li {
        position: relative;
        padding-bottom: 0.625rem;
        vertical-align: middle;
        font-size: 0.85rem;
        font-family: Roboto, sans-serif;
        line-height: 1.25rem;

        &:last-child {
          margin-left: 18px;
          padding: 0;
          border: none;
        }

        a {
          margin-left: 18px;
          padding: 0 0 5px 0;
          border-bottom: solid 2px #ebebeb;
          color: #737373;
          text-decoration: none;
        }
      }
    }

    ul.blog-dropdown-options {
      position: absolute;
      top: 100%;
      left: 50%;
      z-index: 900;
      display: none;
      float: none;
      margin: 0 auto;
      padding: 0;
      width: 8.125rem;
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
      background: #ffffff;
      box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.34);
      text-align: center;
      transform: translateX(-50%);

      li {
        display: block;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid #9c9c9c;
        white-space: nowrap;
        font-size: 0.8125rem;
        line-height: 0.8125rem;

        &:last-child {
          margin: 0;
        }

        a {
          display: block;
          margin-left: 0;
          padding: 0.875rem;
          border-bottom: 0;
          text-decoration: none;
        }
      }
    }

    .search {
      display: flex;
      margin-bottom: 1rem;
      padding-right: 0.625rem;
      width: 100%;

      align-items: stretch;

      .search-button {
        display: inline-block;
        padding: 0.75em 2em;
        border: solid 2px #e6e6e6;
        border-left: none;
        border-radius: 0 50px 50px 0;
        background-color: #f6f6f6;
        color: #777777;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        font-size: 0.75rem;
        font-family: Roboto, sans-serif;
        line-height: 1;
        cursor: pointer;
      }

      input[type=text] {
        padding: 0.125rem 0.5rem;
        min-width: 9.375rem;
        border: solid 2px #e6e6e6;
        border-radius: 1.25rem 0 0 1.25rem;
        box-shadow: none;
        color: #777777;
        font-size: 0.75rem;
      }

      .input-group-field {
        flex: 1 1 0;
        width: auto;
        height: auto;
        border-radius: 0;
      }

      .input-group {
        display: flex;
        margin-bottom: 1rem;
        width: 100%;

        align-items: stretch;

        &:first-child {
          border-radius: 0.3125rem 0 0 0.3125rem;
        }
      }

      .input-group-button {
        display: table-cell;
        flex: 0 0 auto;
        margin: 0;
        padding-top: 0;
        padding-bottom: 0;
        text-align: center;
      }
    }
  }
}