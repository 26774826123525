.subpage.healthcare {
  .container {
    .content-wrapper {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        color: #c23004;
      }
    }
  }

  .content-wrapper.three-col {
    .sidebar {
      nav.sub-navigation {
        .title {
          display: block;
          padding: 1.125rem 2.5rem 1rem 4.1875rem;
          background: #c23004;
          color: #ffffff;
          font-size: 14px;

          &:before {
            position: absolute;
            top: 0;
            left: 0;
            min-height: 100px;
            width: 57px;
            height: auto;
            border-right: none;
            background: #e43805 url('/Themes/EOM/assets/dist/images/icon-healthcare-white.svg') no-repeat center center;
            background-position: 50% 25%;
            color: #ffffff;
            content: '';
          }

          &:after {
            content: 'Healthcare';
          }
        }

        .ul1 {
          padding: 0;

          li {
            border-bottom: none;

            &:after {
              position: absolute;
              bottom: 0;
              left: 10px;
              z-index: 102;
              width: calc(100% - 20px);
              height: 1px;
              border-bottom: dashed 1px #c4c4c4;
              content: '';
            }

            a {
              position: relative;
              border-left: none;
              background-color: #f0f0f0;
              color: #c23004;
              font-size: 14px;

              &:hover {
                background-color: #ebebeb;
              }
            }

            &:last-of-type {
              &:after {
                display: none;
              }
            }

            .ul2 {
              display: block;
              margin: 0;
              padding: 0;
              background-color: #f0f0f0;

              li {
                position: relative;
                list-style: none;

                &:after {
                  display: none;
                }

                a {
                  position: relative;
                  padding: 0 0 10px 35px;
                  border-bottom: none;
                  color: #595959;
                  font-size: 14px;

                  &:before {
                    position: absolute;
                    left: 20px;
                    content: '>';
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .subpage-banner {
    background: #062d89 url('/Themes/EOM/assets/dist/images/bnr-healthcare.jpg');

    .page-title {
      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 10px;
        border-top: solid 1px rgba(0,0,0,0.5);
        background-color: #e43805;
        content: '';
      }
    }
  }
}
