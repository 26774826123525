.content {
  font-style: normal;
  font-family: Roboto,sans-serif;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 1.25rem;
    color: inherit;
    font-weight: 700;
    font-style: normal;
    font-family: Roboto,sans-serif;
    line-height: 1.4;
    text-rendering: optimizeLegibility;

    &.underline {
      padding-bottom: 16px;
      border-bottom: 1px solid $mercury;
    }
  }

  h1 {
    color: #0032a0;
    font-size: 1.5rem;
  }

  h2 {
    color: #0032a0;
    font-size: 1.5rem;

    a {
      text-decoration: underline;
    }
  }

  h3 {
    color: #0032a0;
    font-size: 1.25rem;
  }

  p {
    margin-bottom: 1rem;
    font-style: normal;
    font-size: inherit;
    font-family: Roboto,sans-serif;
    line-height: 2rem;
    text-rendering: optimizeLegibility;
  }

  img {
    max-width: 100%;
  }

  a {
    // color: rgb(2,139,255);
    color: #006ac7;
    text-decoration: none;
    font-weight: 600;

    &:hover,
    &:focus {
      text-decoration: underline;
    }

    &:visited {
      color: #006ac7;
    }
  }

  ul:not(.location-products),
  ol {
    margin-bottom: 1rem;
    margin-left: 1.25rem;
    padding-left: 0;
    list-style-position: outside;
    line-height: 2rem;

    a {
      font-weight: 700;
    }
  }

  ul.color {
    margin: 0;
    list-style: none;

    li {
      padding: 20px;

      &:nth-child(odd) {
        background-color: #f5f5f5;
      }

      &:nth-child(even) {
        background-color: #ebebeb;
      }
    }
  }

  .video-container {
    iframe {
      max-width: 100%;
    }
  }

  hr {
    max-width: 100%;
    width: 100%;
    border-top: 0;
    border-right: 0;
    border-bottom: 1px solid rgba(156, 156, 156, 0.35);
    border-left: 0;
  }

  .multi-list,
  .multi-column {
    display: flex;

    @media only screen and (max-width: 1023px) {
      display: block;

      // margin-left: -15px;
      width: 100%;

      p:nth-child(even) {
        border-right: none;
        border-left: none;
      }
    }

    .col {
      padding-right: 30px;
    }

    .lists,
    .column {
      padding: 0 30px 10px 0;
      width: 100%;

      @media only screen and (max-width: 1023px) {
        padding: 0 15px;
      }

      h3 {
        margin-top: 0;
        padding-bottom: 7px;
      }

      img {
        width: 100%;
      }

      p {
        margin: 0 0 1em 0;
      }

      ul {
        margin: 0;
        width: 100%;
        list-style: none;

        li {
          margin-left: -40px;
          padding: 7px 0;
          font-weight: 500;
          font-style: normal;
          line-height: 22px;

          &:last-child {
            border-bottom: none;
          }
        }
      }

      span {
        color: #ffffff;
        font-weight: 600;
      }
    }
  }

  .pull-quote-left {
    float: left;
    margin: -10px 20px 5px 0;
    min-width: 215px;
    max-width: 230px;

    @media (max-width: 640px) {
      float: none;
      margin: auto;
    }

    h2 {
      margin: 0;
      padding: 0 10px;
      background: #0032a0;
      color: #ffffff !important;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-style: italic;
      font-size: 22px;
      line-height: 39px;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.red {
      background: #dc3704;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.aqua {
      background: #079ed5;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.green {
      background: #929b3c;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.teal {
      background: #288179;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.blue {
      background: #276cad;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.purple {
      background: #5b76b0;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    p {
      margin: 0;
      padding: 10px 15px 15px 15px;
      border: solid 1px #d9d9d9;
      background: #ffffff;
      background: linear-gradient(to bottom, #ffffff 0, #f5f5f5 100%);
      color: #737373;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .pull-quote-center {
    margin: 0 0 20px 0;

    h2 {
      margin: 0;
      padding: 0 15px;
      background: #0032a0;
      color: #ffffff !important;
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 22px;
      line-height: 39px;
    }

    h2.red {
      background: #dc3704;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.aqua {
      background: #079ed5;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.green {
      background: #929b3c;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.teal {
      background: #288179;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.blue {
      background: #276cad;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.purple {
      background: #5b76b0;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    p {
      margin: 0;
      padding: 10px 15px 15px 15px;
      border: solid 1px #d9d9d9;
      background: #ffffff;
      background: linear-gradient(to bottom, #ffffff 0, #f5f5f5 100%);
      color: #737373;
      font-size: 13px;
      line-height: 18px;
    }
  }

  .pull-quote-right {
    float: right;
    clear: right;
    margin: -10px 0 20px 20px;
    min-width: 215px;
    max-width: 230px;

    @media (max-width: 640px) {
      float: none;
      margin: auto;
    }

    h2 {
      margin: 0;
      padding: 0 10px;
      background: #0032a0;
      color: #ffffff !important;
      text-align: center;
      text-transform: uppercase;
      font-weight: 700;
      font-style: italic;
      font-size: 22px;
      line-height: 39px;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.red {
      background: #dc3704;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.aqua {
      background: #079ed5;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.green {
      background: #929b3c;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.teal {
      background: #288179;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.blue {
      background: #276cad;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    h2.purple {
      background: #5b76b0;
      font-style: italic;

      em {
        font-weight: 300;
        font-style: normal;
      }
    }

    p {
      margin: 0;
      padding: 10px 15px 15px 15px;
      border: solid 1px #d9d9d9;
      background: #ffffff;
      background: linear-gradient(to bottom, #ffffff 0, #f5f5f5 100%);
      color: #737373;
      font-size: 13px;
      line-height: 18px;
    }
  }
}
