.search-container {
  display: inline-flex;
  flex-direction: row-reverse;
  margin-bottom: 20px;
  padding: 20px 0;
  width: 100%;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;

  .learn-more-search-container {
    position: relative;
    top: 5px;
    width: 50%;

    .learn-more-search {
      position: relative;
      width: 100%;

      input[type=text] {
        display: block;
        box-sizing: border-box;
        margin: 0 0 1rem;
        padding: 0.5rem;
        width: 100%;
        height: 2.4375rem;
        border: 1px solid #cacaca;
        border-radius: 5px;
        background-color: #fefefe;
        box-shadow: inset 0 1px 2px rgba(10,10,10,0.1);
        color: #0a0a0a;
        font-size: 1rem;
        font-family: inherit;
        transition: box-shadow 0.5s,border-color 0.25s ease-in-out;
      }

      .button {
        position: absolute;
        top: 0;
        right: 0;
        display: inline-block;
        margin: 0 0 1rem 0;
        padding: 0.5rem;
        height: 38px;
        border: 1px solid #cacaca;
        border-radius: 5px;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        background-color: #f2f2f2;
        color: #0032a0;
        vertical-align: middle;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.0625rem;
        font-weight: 700;
        font-size: 1rem;
        font-family: Roboto,sans-serif;
        line-height: 1;
        cursor: pointer;

        -webkit-appearance: none;
      }
    }
  }

  .learn-more-filters {
    width: 50%;

    .learn-more-filter {
      position: relative;
      display: inline-block;
      float: right;
      margin: 0 20px 0 0;

      &:first-child {
        margin-right: 0;
      }

      .filter {
        display: block;
        padding: 15px 10px;
        min-width: 180px;
        width: 100%;
        border: 1px solid #d9d9d9;
        border-radius: 5px;
        background: #fafafa;
        text-align: center;
        cursor: pointer;

        .fa {
          color: #b3b3b3;
        }

        &.active {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
          background: white;
        }
      }
    }

    ul.filter-items {
      position: absolute;
      top: 50px;
      left: 0;
      z-index: 1;
      display: none;
      margin: 0;
      padding: 0;
      width: 100%;
      background-color: white;
      list-style: none;
      font-size: 14px;

      li {
        padding: 7px 12px;
        border: 1px solid #d9d9d9;
        border-bottom: 0;
        text-align: center;

        &:hover {
          background: #f2f2f2;
        }

        &:first-child {
          border-top: 0;
        }

        &:last-child {
          border-bottom: 1px solid #d9d9d9;
        }

        a {
          color: #333333;
          font-weight: 400;
        }
      }
    }
  }
}

.learn-more-current-filter {
    text-transform: uppercase;
    font-family: Roboto,sans-serif;
    margin: 0 0 30px;

    .filter-item {
        display: inline-block;
        padding: 7px 14px;
        border-radius: 20px;
        border: 1px solid #d5d5d5;
        text-transform: uppercase;
        font-size: 14px;
        font-weight: 500;
        letter-spacing: 1px;

        .clear-filter {
            color: #0032a0 !important;

            &:active, &:visited {
                color: #0032a0 !important;
            }

            .fa-close:before {
                color: #0032a0 !important;
            }
        }
    }
}

.learn-more-cards {
  display: inline-flex;
  width: 100%;

  flex-wrap: wrap;
  justify-content: space-between;

  // @media only screen and (max-width: 1024px) {
  //   column-count: 2;
  // }

  // @media only screen and (max-width: 640px) {
  //   column-count: 1;
  // }

  .learn-more-card {
    position: relative;
    margin: 0 0 20px;
    padding: 16px 20px;
    width: 32%;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    vertical-align: top;
    font-family: Roboto,sans-serif;

    @media only screen and (max-width: 1024px) {
      width: 49%;
    }

    @media only screen and (max-width: 640px) {
      width: 100%;
    }

    .details {
      padding-bottom: 40px;

      .category {
        display: inline-block;
        margin: 14px 0 30px;
        padding: 7px 12px;
        border-radius: 5px;
        background: #0032a0;
        color: white;
        text-transform: uppercase;
        font-size: 14px;
      }

      h2 {
        margin-top: 0;
        color: #0032a0;
        font-weight: 700;
        font-size: 18px;

        &:after {
          position: relative;
          display: block;
          margin: 20px 0;
          width: 100%;
          border: 1px solid #e6e6e6;
          content: '';
        }
      }

      p {
        font-size: 0.875rem;
        line-height: 1.25rem;
      }

      span.more,
      span.less {
        display: block;
        margin-top: 5px;
        color: #737373;
        text-transform: uppercase;
        letter-spacing: 1px;
        font-weight: 500;
        font-size: 13px;
        cursor: pointer;

        &:after {
          position: relative;
          display: inline;
          margin-left: 5px;
          color: #b3b3b3;
          font-family: FontAwesome;
        }
        ;
      }

      span.more {
        &:after {
          content: '\f0d7';
        }
      }

      span.less {
        &:after {
          content: '\f0d8';
        }
      }

      a {
        color: #0032a0;
        font-weight: 700;

        span {
          position: absolute;
          bottom: 0;
          left: 0;
          padding: 10px 20px;
          width: 100%;
          width: 100%;
          border-bottom-right-radius: 5px;
          border-bottom-left-radius: 5px;
          background: #f6f6f6;
          color: #0032a0;
          font-weight: 700;

          &:before {
            position: absolute;
            top: 0;
            right: 50px;
            display: block;
            height: 39px;
            border-right: 1px solid #d5d5d5;
            content: '';
          }

          &:after {
            float: right;
            color: #cccccc;
            content: '\f08e';
            font-family: FontAwesome;
          }
        }

        span.sr-only {
          &:before,
          &:after {
            display: none;
          }
        }
      }

      a[href*='.pdf'] {
        span {
          &:after {
            content: '\f1c1';
          }
        }
      }

      a[href*='.mp4'] {
        span {
          &:after {
            content: '\f26c';
          }
        }
      }
    }
  }
}


