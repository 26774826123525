

.print {
  .home footer.primary {
    margin-top: 0;
  }

  footer.primary {
    position: relative;
    clear: both;
    margin-top: 0;
    padding: 2.5rem 0;
    height: 50px;
    background-color: #0033a0;

    .row {
      // display: -webkit-box;
      // display: -ms-flexbox;
      display: flex;
      margin-right: auto;
      margin-left: auto;
      max-width: 75rem;

// -ms-flex-flow: row wrap;

      flex-flow: row wrap;
    }

    .small-12 {
      -ms-flex: 0 0 100%;
          flex: 0 0 100%;
      max-width: 100%;

      -webkit-box-flex: 0;
    }

    @media screen and (min-width: 40em) {
      .medium-6 {
        -ms-flex: 0 0 50%;
            flex: 0 0 50%;
        max-width: 50%;

        -webkit-box-flex: 0;
      }
    }
  }

  @media screen and (max-width: 63.9375em) {
    footer.primary {
      padding-top: 1.5625rem;
      height: auto;
    }
  }

  footer.primary .social-nav {
    position: absolute;
    top: 15px;
    left: 80px;
    margin: 0;
    padding: 0;
  }

  footer.primary .social-nav li {
    display: inline-block;
    margin-right: 1.875rem;
    list-style: none;
  }

  footer.primary .social-nav li a {
    display: block;
    color: #ffffff;
    font-size: 1.75rem;
  }

  footer.primary .social-nav li a.icon-eom-blog {
    position: relative;
    bottom: -0.5rem;
    width: 1.75rem;
    height: 1.75rem;
    background: url('/themes/eom/assets/dist/images/icons/icon-eom-blog.svg') center center no-repeat;
    background-size: contain;
  }

  footer.primary .social-nav li a:hover {
    text-decoration: none;
  }

  footer.primary .social-nav li:last-child {
    margin-right: 0;
  }

  footer.primary .footer-nav {
    position: absolute;
    top: 20px;
    right: 80px;
    margin: 0;
    padding: 0;
  }

  footer.primary .footer-nav li {
    display: inline-block;
    margin-right: 1.25rem;
    list-style: none;
    font-size: 0.75rem;
  }

  footer.primary .footer-nav li:last-child {
    margin-right: 0;
  }

  footer.primary .footer-nav li a {
    border-bottom: solid 1px #ffffff;
    color: #ffffff;
  }

  footer.primary .footer-nav li a:hover {
    text-decoration: none;
  }

  @media screen and (max-width: 63.9375em) {
    footer.primary .social-nav {
      position: relative;
      top: 0;
      left: 50%;
      margin: 1.25rem 0;
      padding: 0;
      -webkit-transform: translate(-50%, 0);
              transform: translate(-50%, 0);
    }

    footer.primary .social-nav li {
      display: inline-block;
      margin-right: 1.875rem;
      list-style: none;
    }

    footer.primary .social-nav li .fa {
      color: #ffffff;
    }

    footer.primary .social-nav li a {
      display: block;
      color: #ffffff;
      font-size: 1.75rem;
    }

    footer.primary .social-nav li a.icon-eom-blog {
      position: relative;
      bottom: -0.5rem;
      width: 1.75rem;
      height: 1.75rem;
      background: url(/themes/eom/assets/dist/images/icons/icon-eom-blog.svg) center center no-repeat;
      background-size: contain;
    }

    footer.primary .social-nav li a:hover {
      text-decoration: none;
    }

    footer.primary .social-nav li:last-child {
      margin-right: 0;
    }

    footer.primary .footer-nav {
      position: relative;
      top: 0;
      right: 0;
      margin: 1.5625rem 0;
      padding: 0;
    }

    footer.primary .footer-nav.float-right {
      float: none !important;
    }

    footer.primary .footer-nav li {
      display: block;
      margin-right: 0;
      list-style: none;
      text-align: center;
      font-size: 0.75rem;
    }

    footer.primary .footer-nav li:last-child {
      margin-right: 0;
    }

    footer.primary .footer-nav li a {
      border-bottom: solid 1px #ffffff;
      color: #ffffff;
    }

    footer.primary .footer-nav li a:hover {
      text-decoration: none;
    }
  }

  .editFL {
    display: inline-block;
    width: 1.375rem;
    height: 1.375rem;
    background: url(../images/bg-btn-edit.png) no-repeat top left;
    text-indent: -9999rem;
  }
}
